import React from "react";
import { Card, CardContent, TextField, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { alpha } from "@mui/material/styles";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import useRestorePassword from "app/hooks/user/useRestorePassword";
import { LoadingButton } from "@mui/lab";
import { RestorePasswordSchema } from "app/utils/schemas/user";

const RestorePassword = () => {
  const { t } = useTranslation();

  const { onSubmit, initialValues, restorePass } = useRestorePassword() 

  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(4),
      }}
    >
      <Div sx={{ mb: 3, display: "inline-flex" }}>
        <img
          className="forgot-pass-logo"
          src={`${ASSET_IMAGES}/logo.png`}
          alt="Jumbo React"
        />
      </Div>
      <Card sx={{ maxWidth: "100%", width: 360, mb: 4 }}>
        <Div sx={{ position: "relative", height: "200px" }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={getAssetPath(`${ASSET_IMAGES}/colin-watts.jpg`)}
          />
          <Div
            sx={{
              flex: 1,
              inset: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              backgroundColor: (theme) =>
                alpha(theme.palette.common.black, 0.5),
              p: (theme) => theme.spacing(3),
            }}
          >
            <Typography
              variant={"h2"}
              sx={{
                color: "common.white",
                fontSize: "1.5rem",
                mb: 0,
                textAlign: "center"
              }}
            >
              {t("resetPassword.subtitle")}
            </Typography>
          </Div>

        </Div>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validateOnMount={false}
            validateOnBlur={false}
            validateOnChange={true}
            validationSchema={RestorePasswordSchema}
            onSubmit={(values, { setSubmitting }) => {
              onSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ handleChange, errors, values }) => (
              <Form autoComplete="off" noValidate>
                <Div sx={{ mb: 3, mt: 1 }}>
                  <Div sx={{ mb: 3, mt: 1 }}>
                    <TextField
                      fullWidth
                      label={t("resetPassword.code")}
                      name="code"
                      value={values.code}
                      onChange={handleChange}
                      error={errors.code}
                      helperText={errors.code && <div>{t(errors.code)}</div>}
                    />
                  </Div>
                  <Div sx={{ mb: 3, mt: 1 }}>
                    <TextField
                      type="password"
                      fullWidth
                      label={t("resetPassword.new")}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={errors.password}
                      helperText={errors.password && <div>{t(errors.password)}</div>}
                    />
                  </Div>
                  <Div sx={{ mb: 3, mt: 1 }}>
                    <TextField
                      type="password"
                      fullWidth
                      label={t("resetPassword.confirm")}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      error={errors.confirmPassword}
                      helperText={errors.confirmPassword && <div>{t(errors.confirmPassword)}</div>}
                    />
                  </Div>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={{ mb: 3 }}
                      loading={restorePass.isLoading}
                    >
                      {t("resetPassword.reset")}
                    </LoadingButton>
                </Div>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Div>
  );
};

export default RestorePassword;
