import { Grid, MenuItem, TextField } from "@mui/material";
import UseListUser from "app/hooks/user/useListUser";
import React from "react";

const UserList = ({
  errors,
  validateField,
  handleChange,
  values,
  roleId = undefined,
}) => {
  const { rows: users } = UseListUser(roleId);

  return (
    <Grid item xs={12} lg={6}>
      <TextField
        id="outlined-select-currency"
        select
        label="Usuarios"
        defaultValue="EUR"
        variant="standard"
        name="userId"
        fullWidth
        error={errors.userId ?? false}
        helperText={errors.userId ?? ""}
        onChange={handleChange}
        onBlur={() => {
          validateField("userId");
        }}
        value={values.userId}
      >
        {users?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {`${option.name} ${option.lastName}`}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default UserList;
