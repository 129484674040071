import Alerts from "app/shared/Alerts";
import { propertyColumns } from "app/utils/tableColumns/property";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getPropertiesReport } from "services/property";
import useUpdateProperty from "./useUpdateProperty";

const useGetProperty = (t) => {
  const columns = propertyColumns(t);
  const { confirmAction } = Alerts();
  const { update } = useUpdateProperty();
  const navigate = useNavigate();

  const { data: properties, isLoading } = useQuery({
    queryKey: "getPropertiesReport",
    queryFn: getPropertiesReport,
  });

  const handleClick = (params) => {
    if (params.field !== "actions")
      navigate("/property/detail/" + params.row.id, {
        state: params.row,
      });
    else
      confirmAction(
        update,
        params.id,
        t("property.deleteText"),
        t("property.delete"),
        t("general.cancel")
      );
  };

  return {
    properties,
    isLoading,
    columns,
    handleClick,
  };
};

export default useGetProperty;
