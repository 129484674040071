import hydraAPI from "../config/hydraAPI"

export const create = async (data) => {
  const result = await hydraAPI().post("/permissions/create", data);
  return result.data.data;
};

export const getByUser = async (data) => {
  const result = await hydraAPI().post("/permissions/get", data);
  return result.data.data;
};
