import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { CreateContactUsSchema } from 'app/utils/schemas/contactUs';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getContactUsType, postContactUs } from 'services/contactUs';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const ContactUsFormContent = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { data: contactUsType } = useQuery({
    queryFn: getContactUsType
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: postContactUs,
    onSuccess: () => {
      Toast.fire({
        icon: "success",
        title: "El registro se creó correctamente",
      });
      navigate('/contact-us')
    },
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: error,
      });
    }
  })

  return (
    <Box sx={{ width: "100%" }}>
      <Formik
        initialValues={{
          acceptPolicies: false
        }}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={CreateContactUsSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          mutate(values)
          resetForm()
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          errors,
          values,
          validateField,
        }) => (
          <Form autoComplete="off" noValidate>
            <Grid
              container
              spacing={4}
              style={{ width: "100%" }}
              justifyContent={"left"}
              alignItems={"left"}
            >
              <Grid item xs={12} lg={6}>
                <FormControl
                  required
                  variant="standard"
                  onChange={handleChange}
                  fullWidth
                  error={errors.contactUsTypeId ?? false}
                  helperText={errors.contactUsTypeId && <div>{t(errors.contactUsTypeId)}</div>}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    {t("forms.contactType")}
                  </InputLabel>
                  <Select
                    name="contactUsTypeId"
                    value={values.contactUsTypeId}
                    onChange={handleChange}
                    onBlur={() => { validateField('contactUsTypeId') }}
                  >
                    {contactUsType?.map((state) => (
                      <MenuItem value={state.id}>{state.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={t('forms.name')}
                  name="name"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => { validateField('name') }}
                  value={values.name}
                  error={errors.name ?? false}
                  helperText={errors.name ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={`${t('forms.lastName')}`}
                  name="lastName"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => { validateField('lastName') }}
                  value={values.lastName}
                  error={errors.lastName ?? false}
                  helperText={errors.lastName ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={`${t('forms.email')}`}
                  name="email"
                  variant="standard"
                  onChange={handleChange}
                  placeholder="Apto/casa, torre, residencia"
                  onBlur={() => { validateField('email') }}
                  value={values.email}
                  error={errors.email ?? false}
                  helperText={errors.email ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={t('forms.indicative')}
                  name="indicative"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => { validateField('indicative') }}
                  value={values.indicative}
                  error={errors.indicative ?? false}
                  helperText={errors.indicative ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={t('forms.phone')}
                  name="phone"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => { validateField('phone') }}
                  value={values.phone}
                  error={errors.phone ?? false}
                  helperText={errors.phone ?? ''}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={t('forms.comments')}
                  multiline
                  name="comment"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => { validateField('comment') }}
                  value={values.comment}
                  error={errors.comment ?? false}
                  helperText={errors.comment ?? ''}
                />
              </Grid>
              <Grid item xs={12} textAlign={"center"}>
                <FormControlLabel
                  control={
                    <Checkbox
                      required
                      name="acceptPolicies"
                      variant="standard"
                      onChange={handleChange}
                      checked={values.acceptPolicies}
                      error={errors.acceptPolicies ?? false}
                      helperText={errors.acceptPolicies ?? ''}
                    />
                  }
                  label={t('forms.acceptPolicies')}
                />

              </Grid>

              <Grid item xs={12} textAlign={"center"}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isLoading}
                >
                  {t("forms.create")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ContactUsFormContent;
