import Alerts from "app/shared/Alerts";
import { resourcesColumns } from "app/utils/tableColumns/resources";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "services/resources";
import useUpdateResource from "./useUpdateResource";

const useGetResources = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const columns = resourcesColumns(t);
  const { update } = useUpdateResource();
  const { confirmAction } = Alerts()

  const { data: resources, isLoading } = useQuery({
    queryKey: "getResources",
    enabled: id !== undefined,
    queryFn: () => {
      return get({ propertyId: id });
    },
  });

  const handleClick = (params) => {
    if (params.field === "actions")
      confirmAction(
        update,
        params.id,
        t("resources.deleteText"),
        t("resources.delete"),
        t("general.cancel")
      );
  };

  return {
    columns,
    resources,
    isLoading,
    handleClick,
  };
};

export default useGetResources;
