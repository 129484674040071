import { rolesByUser } from "app/helpers";
import hydraAPI from "services/config/hydraAPI";

export const postUser = async (data) => {
    const userRV = JSON.parse(localStorage.getItem('userRV'))
    const result = await hydraAPI().post("user/create", { ...data, createdBy: userRV.email });
    return result.data.data;
}

export const patchUser = async (data) => {
    const result = await hydraAPI().patch("user", data);
    return result;
}

export const getUser = async (data) => {
    const result = await hydraAPI().post("user/get", data);
    return result.data.data;
}

export const getUserByUserIdAndRolId = async () => {
    const userRV = JSON.parse(localStorage.getItem('userRV'))
    const data = { rolId: userRV.role.id, userId: userRV.id }
    const result = await hydraAPI().post("user/related/get", data);
    return result.data.data;
}

export const getForUserRelation = async (data) => {
    const result = await hydraAPI().post("user/toRelate", data);
    return result.data.data;
}

export const getRoles = async (data) => {
    const result = await hydraAPI().post("role/get", data);

    const userLocal = JSON.parse(localStorage.getItem('userRV'))
    const allowedRoles = rolesByUser(userLocal, result.data.data)
    return allowedRoles
}

export const postUserConfiguration = async (data) => {
    const result = await hydraAPI().post("userConfiguration/create", data);
    return result;
}

export const getUserConfiguration = async (data) => {
    const result = await hydraAPI().post("userConfiguration/get", data);
    return result.data.data;
}

export const getUserRelation = async (data) => {
    const result = await hydraAPI().post("userRelation/get", data);
    return result.data.data;
}

export const postUserRelation = async (data) => {
    const userRV = JSON.parse(localStorage.getItem('userRV'))
    const result = await hydraAPI().post("userRelation/create", { ...data, createdBy: userRV.email });
    return result;
}

export const postAddress = async (data) => {
    const userRV = JSON.parse(localStorage.getItem('userRV'))
    const result = await hydraAPI().post("address/create", { ...data, createdBy: userRV.email });
    return result;
}

export const getAddress = async (userId) => {
    const result = await hydraAPI().post("address/get", { userId });
    return result.data.data;
}

export const updateUser = async (data) => {
    const result = await hydraAPI().put("user/update", data);
    return result.data.data;
}