import React from "react";
import { enUS, esES } from "@mui/material/locale";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { useTranslation } from "react-i18next";

const LocalizationOptions = () => {
  const { i18n, t } = useTranslation();
  
  const languages = [
    {
      label: t("language.spanish"),
      locale: "es-ES",
      muiLocale: esES,
    },
    {
      label: t("language.english"),
      locale: "en-US",
      muiLocale: enUS,
    },
  ];
  const { setMuiLocale } = useJumboTheme();
  const [activeLocale, setActiveLocale] = React.useState(languages[0]);

  const handleChange = (event) => {
    const localeIndex = languages.findIndex(
      (language) => language.locale === event.target.value
    );
    if (localeIndex !== -1) {
      i18n.changeLanguage(languages[localeIndex].locale).then(() => {
        setActiveLocale(languages[localeIndex]);
        setMuiLocale(languages[localeIndex].muiLocale);
      });
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        size={"small"}
        labelId="select-locale"
        id="customizer-select-locale"
        value={activeLocale.locale}
        label="Language"
        onChange={handleChange}
      >
        {languages.map((language) => (
          <MenuItem key={language.locale} value={language.locale}>
            {language.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LocalizationOptions;
