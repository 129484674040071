import { useQuery } from 'react-query';
import { getRoles } from 'services/user';

const UseRole = () => {
    const { data: roles } = useQuery({
        queryKey: "getRoles",
        queryFn: getRoles
    })

    return {
        roles
    }
}

export default UseRole;
