export const ROLES_ID = {
    ADMIN: 1,
    AGENCIA: 2,
    SUB_AGENCIA: 3,
    ASESOR: 4,
    REALTOR: 5,
    DUEÑO: 6,
    CLIENTE: 7,
    IBO: 8
}

export const REAL_VISION_CODE = "100001";