import Swal from "sweetalert2";

// const Toast = Swal.mixin({
//   toast: true,
//   position: "center",
//   showConfirmButton: false,
//   timer: 2000,
//   timerProgressBar: true,
//   onOpen: (toast) => {
//     toast.addEventListener("mouseenter", Swal.stopTimer);
//     toast.addEventListener("mouseleave", Swal.resumeTimer);
//   },
// });

const Alerts = () => {
  const confirmAction = (
    update,
    id,
    text,
    confirmButtonText,
    cancelButtonText
  ) => {
    Swal.fire({
      text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7352C7",
      cancelButtonColor: "#d33",
      confirmButtonText,
      cancelButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        update({ id, active: false });
      }
    });
  };

  const success = (title, text, icon) => {
    Swal.fire(title, text, icon);
  };

  return { confirmAction, success };
};

export default Alerts;
