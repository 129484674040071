import { Button } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { download } from "app/utils/exportData/excel";

const ExportData = ({ data }) => {
  return (
    <Button variant="contained" onClick={() => download(data)}>
      <DownloadForOfflineIcon sx={{ fontSize: "23px" }} />
    </Button>
  );
};

export default ExportData;
