
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export const currencyColumns = (t) => {
    return [
        { field: "id", headerName: "ID", width: 90 },
        {
            field: "name",
            headerName: t("general.name"),
            editable: false,
            width: 300
        },
        {
            field: "code",
            headerName: t("general.code"),
            editable: false,
            width: 100,
        },
        {
            field: "active",
            headerName: t("general.active"),
            editable: false,
            width: 100,
            renderCell: (params) => {
                return params.value ? <CheckCircleOutlineIcon /> : <CancelIcon />
            }
        },
        {
            field: "actions",
            headerName: t("general.actions"),
            type: "string",
            editable: false,
            width: 170,
            /* renderCell: (params) => {
                return <Actions />;
            }, */
        },
    ];
}