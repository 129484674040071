import { useQuery } from "react-query";
import { getUser, getUserByUserIdAndRolId } from "services/user";
import { ROLES_ID } from "app/utils/constants/general";
import { userColumns } from "app/utils/tableColumns/user";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Alerts from "app/shared/Alerts";
import useUpdateUser from "./useUpdateUser";

const UseListUser = (roleId) => {
  const { t } = useTranslation();
  const columns = userColumns(t);
  const userRV = JSON.parse(localStorage.getItem("userRV"));
  const navigate = useNavigate();
  const { confirmAction } = Alerts();
  const { update } = useUpdateUser();

  const { data: rows, isloading } = useQuery({
    queryKey: "getUser",
    queryFn: () => {
      return getUser({ roleId });
    },
  });

  const { data: rowsMain, isloadingMain } = useQuery({
    queryKey: "getUserByUserIdAndRolId",
    queryFn: getUserByUserIdAndRolId,
  });

  const handleClick = (params) => {
    if (params.field !== "actions") navigate(`/user/detail/${params.id}`);
    else confirmAction(update, params.id, t("user.deleteText"), t("user.delete"), t("user.cancel"));
  };

  const handleChangeFilter = (value) => {
    // let userArray =  userRV.role.id === ROLES_ID.ADMIN ? rows : rowsMain;
    if(userRV.role.id === ROLES_ID.ADMIN) {
      rows.filter(item => item.role.id === value)
    }
  }

  return {
    columns,
    rows,
    isloading,
    rowsMain: userRV.role.id === ROLES_ID.ADMIN ? rows : rowsMain,
    isloadingMain,
    handleClick,
    handleChangeFilter
  };
};

export default UseListUser;
