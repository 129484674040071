export const contactUsColumns = (t) => {
  return [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: t("general.name"),
      editable: false,
      width: 200,
    },
    {
      field: "lastName",
      headerName: t("general.lastName"),
      editable: false,
      width: 200,
    },
    {
      field: "comment",
      headerName: t("contactUs.comment"),
      editable: false,
      width: 250,
    },
    {
      field: "actions",
      headerName: t("general.actions"),
      type: "string",
      editable: false,
      width: 170,
    },
  ];
};
