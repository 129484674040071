import { Grid } from '@mui/material';
import CardButton from 'app/layouts/card-button/CardButton';
import ListDataGrid from 'app/layouts/card-datagrid/ListDataGrid';
import { currencyColumns } from 'app/utils/tableColumns/currency';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getCurrency } from 'services/getCurrency.js'

const Currency = () => {
    
    const { t } = useTranslation()

    const { data: rows, isLoading } = useQuery({
        queryKey: "getCurrency",
        queryFn: getCurrency
    })

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
                <CardButton disabled={true} />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ListDataGrid rows={rows} columns={currencyColumns(t)} isLoading={isLoading} />
            </Grid>
        </Grid>
    );
}

export default Currency;
