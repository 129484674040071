import Div from "@jumbo/shared/Div";
import { CircularProgress, Typography } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Div
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(3),
        m: "auto",
      }}
    >
      <CircularProgress />
      <Typography variant={"h6"} color={"text.secondary"} mt={2}>
        Loading
      </Typography>
    </Div>
  );
};

export default Loading;
