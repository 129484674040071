import { Box, IconButton, ListItemText, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

function generate(element) {
    return [0, 1, 2].map((value) =>
        React.cloneElement(element, {
            key: value,
        }),
    );
}

const ListView = () => {
    return (
        <Box sx={{ width: "100%" }} paddingX={5}>
            <Typography variant={"h4"} my={2}>
                Lista de roles
            </Typography>
            <List>
                {generate(
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText
                            primary="Single-line item"
                            secondary={'Secondary text'}
                        />
                    </ListItem>
                )}
            </List>
        </Box>
    );
}

export default ListView;
