import { Box } from '@mui/material';
import Loading from 'app/layouts/shared/loading';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUserConfiguration, } from 'services/user';
import ConfigurationForm from '../../configuration/components/configurationForm';

const ConfigInformation = () => {
    const { userId } = useParams()
    const [initialValues, setInitialValues] = useState(undefined)

    useQuery({
        queryKey: "getUserConfiguration",
        queryFn: () => getUserConfiguration({ id: userId }),
        retry: false,
        onSuccess: (response) => {
            if (response) {
                setInitialValues({
                    identification: response[0].identification,
                    agencyName: response[0].agencyName,
                    description: response[0].description
                })
            } else {
                setInitialValues({
                    identification: "",
                    agencyName: "",
                    description: ""
                })
            }
        }
    })

    if (!initialValues) { return <Loading /> }

    return (
        <Box sx={{ width: "100%" }}>
            <ConfigurationForm userId={userId} initialValues={initialValues} viewDetail />
        </Box>
    );
}

export default ConfigInformation;
