import hydraAPI from "services/config/hydraAPI";

export const get = async (data) => {
    const result = await hydraAPI().post(`resources/get`, data);
    return result.data.data;
};

export const update = async (data) => {
    const result = await hydraAPI().put(`resources/update`, data);
    return result.data.data;
};