import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { create } from "services/permissions";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const usePermissions = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: assign, isLoading } = useMutation({
    mutationFn: create,
    onSuccess: (data) => {
      queryClient.setQueryData(["createPermissions"], () => {
        return data;
      });
      Toast.fire({
        icon: "success",
        title: t("title.assignedPermissions"),
      });
    },
  });

  return {
    assign,
    isLoading,
  };
};

export default usePermissions;
