import React from "react";
import Page from "@jumbo/shared/Page";
import SubAgencyList from "app/pages/subAgency/list";

const subAgencyRoutes = [
    /* {
        path: "/subagency/create",
        element: <Page component={} />
    },
    {
        path: "/subagency/create/:userId",
        element: <Page component={} />
    }, */
    {
        path: "/subagency/list",
        element: <Page component={SubAgencyList} />
    },
    {
        path: "/subagency/detail/:subAgencyId",
        element: <Page component={SubAgencyList} />
    },
];
export default subAgencyRoutes;
