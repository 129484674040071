import { useTranslation } from "react-i18next";
import MediaForm from "./components/media/Media";
import { Form, Formik } from "formik";
import List from "./components/media/List";

const Media = () => {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        resourceTypeId: 1,
        file: [],
      }}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ values, setFieldValue }) => (
        <Form autoComplete="off" noValidate>
          <MediaForm
            t={t}
            values={values}
            setFieldValue={setFieldValue}
          />
          <List />
        </Form>
      )}
    </Formik>
  );
};

export default Media;
