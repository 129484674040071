import { Box, Grid } from "@mui/material";
import React from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { DataGrid } from "@mui/x-data-grid";
import Actions from "./components/Actions";
import Filters from "./components/Filters";

const CustomersList = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "firstName",
      headerName: "First name",
      width: 150,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last name",
      width: 150,
      editable: true,
    },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 160,
      valueGetter: (params) =>
        `${params.row.firstName || ""} ${params.row.lastName || ""}`,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "string",
      editable: false,
      width: 170,
      renderCell: (params) => {
        return <Actions property={params.row} />;
      },
    },
  ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
    { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
    { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
    { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
    { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  ];

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={12}>
        <Filters />
      </Grid>
      <Grid item xs={12} lg={12}>
        <JumboCardQuick title={"Lista de clientes"}>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              // checkboxSelection
              editRowsModel={false}
              resizable
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default CustomersList;
