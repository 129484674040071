import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CardButton = ({ disabled, navigateTo }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <JumboCardQuick>
            <Stack spacing={2} direction="row" justifyContent={"center"}>
                <Button variant="contained" disabled={disabled} onClick={() => navigate(navigateTo)} >
                    {t("forms.create")}
                </Button>
            </Stack>
        </JumboCardQuick>
    );
}

export default CardButton;
