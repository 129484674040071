import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import useConfigurationUser from 'app/hooks/user/useConfigurationUser';
import { CreateUserConfigurationSchema } from 'app/utils/schemas/user';
import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfigurationForm = ({
    userId,
    initialValues = {
        identification: "",
        agencyName: "",
        description: ""
    },
    viewDetail
}) => {
    const disableFields = initialValues.identification !== "" ? true : false
    const { t } = useTranslation()
    const { onSubmit, isLoading } = useConfigurationUser(userId)

    return (
        <Formik
            initialValues={initialValues}
            validateOnMount={false}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={CreateUserConfigurationSchema(t)}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values)
                setSubmitting(false);
            }}
        >
            {({
                handleChange,
                errors,
                values,
                validateField,
            }) => (
                <Form autoComplete="off" noValidate>
                    <Grid
                        container
                        spacing={4}
                        style={{ width: "100%" }}
                        justifyContent={"left"}
                        alignItems={"left"}
                    >
                        <Grid item xs={12} lg={6}>
                            <TextField
                                required
                                fullWidth
                                label={t('forms.identificationNumber')}
                                name="identification"
                                variant="standard"
                                onChange={handleChange}
                                onBlur={() => { validateField('identification') }}
                                value={values.identification}
                                error={errors.identification ?? false}
                                helperText={errors.identification ?? ''}
                                disabled={disableFields}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                required
                                fullWidth
                                label={t('forms.agencyName')}
                                name="agencyName"
                                variant="standard"
                                onChange={handleChange}
                                onBlur={() => { validateField('agencyName') }}
                                value={values.agencyName}
                                error={errors.agencyName ?? false}
                                helperText={errors.agencyName ?? ''}
                                disabled={disableFields}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <TextField
                                required
                                fullWidth
                                label={t('forms.generalDescription')}
                                name="description"
                                variant="standard"
                                onChange={handleChange}
                                onBlur={() => { validateField('description') }}
                                value={values.description}
                                error={errors.description ?? false}
                                helperText={errors.description ?? ''}
                                disabled={disableFields}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign={"center"}>
                            {
                                (initialValues.identification === "" || !viewDetail) && (
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{ mb: 3 }}
                                        loading={isLoading}
                                    >
                                        {
                                            // data ? t("forms.edit") : t("forms.create")
                                        }
                                        {t("forms.create")}
                                    </LoadingButton>
                                )
                            }

                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>

    );
}

export default ConfigurationForm;
