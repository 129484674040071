// /* eslint-disable no-unused-vars */
// import React from "react";
// import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
// import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import PeopleIcon from "@mui/icons-material/People";
// import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
// import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

// const menus = [
//   {
//     label: "sidebar.menuItem.setting",
//     type: "collapsible",
//     icon: <ModeEditOutlinedIcon sx={{ fontSize: 20 }} />,
//     children: [
//       {
//         uri: "/configuration/city",
//         label: "sidebar.menuItem.cities",
//         type: "nav-item",
//       },
//       {
//         uri: "/configuration/amenties",
//         label: "sidebar.menuItem.amenities",
//         type: "nav-item",
//       },
//       {
//         uri: "/configuration/currency",
//         label: "sidebar.menuItem.currency",
//         type: "nav-item",
//       },
//       {
//         uri: "/configuration/property-type",
//         label: "sidebar.menuItem.propertyType",
//         type: "nav-item",
//       },
//     ],
//   },
//   {
//     label: "sidebar.menu.contactUs",
//     type: "collapsible",
//     icon: <ContactPhoneIcon sx={{ fontSize: 20 }} />,
//     children: [
//       {
//         uri: "/undefined",
//         label: "sidebar.menuItem.list",
//         type: "nav-item",
//       },
//       {
//         uri: "/undefined",
//         label: "sidebar.menuItem.types",
//         type: "nav-item",
//       },
//     ],
//   },
//   {
//     label: "sidebar.menuItem.property",
//     type: "collapsible",
//     icon: <MapsHomeWorkIcon sx={{ fontSize: 20 }} />,
//     children: [
//       {
//         uri: "/property",
//         label: "sidebar.menuItem.list",
//         type: "nav-item",
//       },
//       {
//         uri: "/property",
//         label: "sidebar.menuItem.report",
//         type: "nav-item",
//       },
//     ],
//   },
//   {
//     label: "sidebar.menuItem.users",
//     type: "collapsible",
//     icon: <PeopleIcon sx={{ fontSize: 20 }} />,
//     children: [
//       {
//         uri: "/users",
//         label: "sidebar.menuItem.list",
//         type: "nav-item",
//         icon: <PersonAddAltIcon sx={{ fontSize: 20 }} />,
//       },
//     ],
//   },
// ];

// export default menus;

const MenuItems = () => {

  const menu = JSON.parse(localStorage.getItem('menu'))

  return {
    menu
  }
}

export default MenuItems
