import { Box, Button, Grid, Stack } from "@mui/material";
import React from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import useGetProperty from "app/hooks/property/useGetProperty";
import ExportData from "app/shared/ExportData";
import { buildExportPropertyData } from "app/utils/formats";
import { Link } from "react-router-dom";
import ControlPointIcon from '@mui/icons-material/ControlPoint';

const List = () => {
  const { t } = useTranslation();
  const { properties, isLoading, columns, handleClick } = useGetProperty(t);

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={12}>
        <JumboCardQuick>
          <Grid item xs={12} lg={12}>
            <Stack spacing={2} direction="row" justifyContent={"end"}>
              <Link to={"/property/create"} style={{ textDecoration: "none" }}>
                <Button variant="contained">
                  {" "}
                  <ControlPointIcon sx={{ fontSize: "23px" }} />
                </Button>
              </Link>
              <ExportData data={buildExportPropertyData(properties)} t={t} />
            </Stack>
          </Grid>
          <Box sx={{ height: 700, width: "100%" }}>
            <DataGrid
              sx={{ marginTop: "50px" }}
              rows={properties ?? []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              onCellClick={handleClick}
              editRowsModel={false}
              resizable={true}
              loading={isLoading}
              experimentalFeatures={{ newEditingApi: true }}
              initialState={{ pinnedColumns: { right: ['actions']}}}
            />
          </Box>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default List;
