import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import UseCities from 'app/hooks/useCities';
import UsePropertyFilter from 'app/hooks/usePropertyFilter';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FilterForm = () => {
    const { t } = useTranslation()
    const { cities, setCityText } = UseCities()
    const { stateType, propiertyType, businessType, mutate, isLoading } = UsePropertyFilter()

    const { handleChange, errors, values, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            active: true,
            cityId: undefined
        },
        onSubmit: (values, { setSubmitting }) => {
            const req = {
                active: values.active,
                cityId: values.cityId,
                code: values.code,
                businessTypeId: values.businessTypeId,
                propertyTypeId: values.propertyTypeId,
                stateTypeId: values.stateTypeId
            }
            mutate(req)
            setSubmitting(false);
        },
    });

    return (
        <Accordion>
            <AccordionSummary>
                Filters
            </AccordionSummary>
            <AccordionDetails>
                <form onSubmit={handleSubmit}>
                    <Grid
                        container
                        spacing={4}
                        style={{ width: "100%" }}
                        justifyContent={"left"}
                        alignItems={"left"}
                    >
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl

                                fullWidth
                                variant="standard"
                                onChange={handleChange}
                                error={errors.cityId ?? false}
                                helperText={errors.cityId && <div>{t(errors.cityId)}</div>}
                            >
                                <Autocomplete
                                    disablePortal
                                    options={cities ?? []}
                                    onChange={(_, value) => setFieldValue("cityId", value.id)}
                                    onKeyUp={(e) => setCityText(e.target.value)}
                                    renderInput={(params) => <TextField {...params}
                                        variant="standard" label={t('forms.city')} />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <TextField

                                fullWidth
                                label={t('forms.customCode')}
                                name="code"
                                variant="standard"
                                onChange={handleChange}
                                value={values.code}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl

                                variant="standard"
                                onChange={handleChange}
                                fullWidth
                            >
                                <InputLabel id="demo-simple-select-standard-label">
                                    {t("forms.propertyType")}
                                </InputLabel>
                                <Select
                                    value={values.propertyTypeId}
                                    onChange={handleChange}
                                    name="propertyTypeId"
                                >
                                    {propiertyType?.map((property) => (
                                        <MenuItem value={property.id}>{property.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl

                                fullWidth
                                variant="standard"
                                onChange={handleChange}
                                error={errors.businessTypeId ?? false}
                                helperText={
                                    errors.businessTypeId && <div>{t(errors.businessTypeId)}</div>
                                }
                            >
                                <InputLabel id="demo-simple-select-standard-label">
                                    {t("forms.businessType")}
                                </InputLabel>
                                <Select
                                    value={values.businessTypeId}
                                    onChange={handleChange}
                                    name="businessTypeId"
                                >
                                    {businessType?.map((business) => (
                                        <MenuItem value={business.id}>{business.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <FormControl

                                variant="standard"
                                onChange={handleChange}
                                fullWidth
                            >
                                <InputLabel id="demo-simple-select-standard-label">
                                    {t("forms.propertyStatus")}
                                </InputLabel>
                                <Select
                                    name="stateTypeId"
                                    value={values.stateTypeId}
                                    onChange={handleChange}
                                >
                                    {stateType?.map((state) => (
                                        <MenuItem value={state.id}>{state.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3} textAlign={"center"}>
                            <FormControlLabel
                                control={
                                    <Checkbox

                                        name="active"
                                        variant="standard"
                                        onChange={handleChange}
                                        checked={values.active}
                                    />
                                }
                                label={t('forms.active')}
                            />

                        </Grid>

                        <Grid item xs={12} textAlign={"center"}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3 }}
                                loading={isLoading}
                            >
                                {t("forms.create")}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </AccordionDetails>
        </Accordion>
    );
}

export default FilterForm;
