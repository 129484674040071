import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ConfigurationForm from './components/configurationForm';

const UserConfiguration = () => {
    const { t } = useTranslation()
    const { userId } = useParams()

    return (
        <JumboCardQuick title={t('title.configUser')}>
            <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                    <ConfigurationForm userId={userId} />
                </Grid>
            </Grid>
        </JumboCardQuick>

    );
}

export default UserConfiguration;
