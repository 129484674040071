import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import UseCities from "app/hooks/useCities";
import UseListUser from "app/hooks/user/useListUser";
import { formatUser, generalFormat } from "app/utils/formats";
import { useQuery } from "react-query";
import { getCurrency } from "services/getCurrency";

const Information = ({
  t,
  handleChange,
  values,
  errors,
  stateType,
  propiertyType,
  measurements,
  setFieldValue,
  businessType,
  typeForm,
  isLoadingUpdate,
}) => {
  const { cities, setCityText } = UseCities();
  const { rows: users } = UseListUser();
  const { data: currency } = useQuery({
    queryKey: "getCurrency",
    queryFn: getCurrency,
  });
  return (
    <>
      <Grid item xs={4}>
        <TextField
          required
          fullWidth
          label={t("forms.title")}
          name="title"
          variant="standard"
          onChange={handleChange}
          value={values.title}
          error={errors.title ?? false}
          helperText={errors.title && <div>{t(errors.title)}</div>}
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl
          required
          variant="standard"
          onChange={handleChange}
          fullWidth
          error={errors.city ?? false}
          helperText={errors.city && <div>{t(errors.city)}</div>}
        >
          <Autocomplete
            disablePortal
            options={cities ?? []}
            value={values.city.label}
            onChange={(_, value) =>
              setFieldValue("city", { label: value.label, id: value.id })
            }
            onKeyUp={(e) => setCityText(e.target.value)}
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("general.city")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}, {option?.state}
                <br />
                {option?.country}
              </Box>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          required
          variant="standard"
          onChange={handleChange}
          fullWidth
          error={errors.stateType ?? false}
          helperText={errors.stateType && <div>{t(errors.stateType)}</div>}
        >
          <Autocomplete
            disablePortal
            options={generalFormat(stateType) ?? []}
            value={values.stateType.label}
            onChange={(_, value) =>
              setFieldValue("stateType", { label: value.label, id: value.id })
            }
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("forms.propertyStatus")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}
              </Box>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl
          required
          variant="standard"
          onChange={handleChange}
          fullWidth
          error={errors.propertyType ?? false}
          helperText={
            errors.propertyType && <div>{t(errors.propertyType)}</div>
          }
        >
          <Autocomplete
            disablePortal
            options={generalFormat(propiertyType) ?? []}
            value={values.propertyType.label}
            onChange={(_, value) =>
              setFieldValue("propertyType", {
                label: value.label,
                id: value.id,
              })
            }
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("forms.propertyType")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}
              </Box>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl
          required
          fullWidth
          variant="standard"
          onChange={handleChange}
          error={errors.measurement ?? false}
          helperText={errors.measurement && <div>{t(errors.measurement)}</div>}
        >
          <Autocomplete
            disablePortal
            options={generalFormat(measurements) ?? []}
            value={values.measurement.label}
            onChange={(_, value) =>
              setFieldValue("measurement", { label: value.label, id: value.id })
            }
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("forms.measurement")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}
              </Box>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField
          required
          fullWidth
          type={"text"}
          label={t("forms.constructionArea")}
          variant="standard"
          value={values.buildArea}
          onChange={handleChange}
          name="buildArea"
          error={errors.buildArea ?? false}
          helperText={errors.buildArea && <div>{t(errors.buildArea)}</div>}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required
          fullWidth
          type={"text"}
          label={t("forms.privateArea")}
          variant="standard"
          value={values.privateArea}
          onChange={handleChange}
          name="privateArea"
          error={errors.privateArea ?? false}
          helperText={errors.privateArea && <div>{t(errors.privateArea)}</div>}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required
          fullWidth
          type={"text"}
          label={t("forms.groundArea")}
          variant="standard"
          value={values.groundArea}
          onChange={handleChange}
          name="groundArea"
          error={errors.groundArea ?? false}
          helperText={errors.groundArea && <div>{t(errors.groundArea)}</div>}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          type={"number"}
          required
          fullWidth
          label={t("forms.yearOfConstruction")}
          variant="standard"
          value={values.yearConstruction}
          onChange={handleChange}
          name="yearConstruction"
          error={errors.yearConstruction ?? false}
          helperText={
            errors.yearConstruction && <div>{t(errors.yearConstruction)}</div>
          }
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          type={"number"}
          required
          fullWidth
          label={t("forms.numberOfRooms")}
          variant="standard"
          value={values.roomsNumber}
          onChange={handleChange}
          name="roomsNumber"
          error={errors.roomsNumber ?? false}
          helperText={errors.roomsNumber && <div>{t(errors.roomsNumber)}</div>}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required
          fullWidth
          type={"number"}
          label={t("forms.numberOfBathrooms")}
          variant="standard"
          value={values.bathroomsNumber}
          onChange={handleChange}
          name="bathroomsNumber"
          error={errors.bathroomsNumber ?? false}
          helperText={
            errors.bathroomsNumber && <div>{t(errors.bathroomsNumber)}</div>
          }
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          required
          fullWidth
          label={t("forms.parkingStall")}
          type={"number"}
          variant="standard"
          value={values.parkingNumber}
          onChange={handleChange}
          name="parkingNumber"
          error={errors.parkingNumber ?? false}
          helperText={
            errors.parkingNumber && <div>{t(errors.parkingNumber)}</div>
          }
        />
      </Grid>

      <Grid item xs={4}>
        <FormControl
          required
          fullWidth
          variant="standard"
          onChange={handleChange}
          error={errors.owner ?? false}
          helperText={errors.owner && <div>{t(errors.owner)}</div>}
        >
          <Autocomplete
            disablePortal
            options={formatUser(users) ?? []}
            value={values.owner.label}
            onChange={(_, value) =>
              setFieldValue("owner", { label: value.label, id: value.id })
            }
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("property.owner")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}
              </Box>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          required
          fullWidth
          variant="standard"
          onChange={handleChange}
          error={errors.seller ?? false}
          helperText={errors.seller && <div>{t(errors.seller)}</div>}
        >
          <Autocomplete
            disablePortal
            options={formatUser(users) ?? []}
            value={values.seller.label}
            onChange={(_, value) =>
              setFieldValue("seller", { label: value.label, id: value.id })
            }
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("property.seller")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}
              </Box>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          required
          fullWidth
          variant="standard"
          onChange={handleChange}
          error={errors.businessType ?? false}
          helperText={
            errors.businessType && <div>{t(errors.businessType)}</div>
          }
        >
          <Autocomplete
            disablePortal
            options={generalFormat(businessType) ?? []}
            value={values.businessType.label}
            onChange={(_, value) =>
              setFieldValue("businessType", {
                label: value.label,
                id: value.id,
              })
            }
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("forms.businessType")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}
              </Box>
            )}
          />
        </FormControl>
      </Grid>
      {values.businessType.id !== 2 && (
        <Grid item xs={4}>
          <TextField
            required
            fullWidth
            type={"number"}
            label={t("forms.rentalTime")}
            variant="standard"
            value={values.rentalTime}
            onChange={handleChange}
            name="rentalTime"
            error={errors.rentalTime ?? false}
            helperText={errors.rentalTime && <div>{t(errors.rentalTime)}</div>}
          />
        </Grid>
      )}
      <Grid item xs={4}>
        <FormControl
          required
          fullWidth
          variant="standard"
          onChange={handleChange}
          error={errors.currency ?? false}
          helperText={errors.currency && <div>{t(errors.currency)}</div>}
        >
          <Autocomplete
            disablePortal
            options={generalFormat(currency) ?? []}
            value={values.currency.label}
            onChange={(_, value) =>
              setFieldValue("currency", {
                label: value.label,
                id: value.id,
              })
            }
            noOptionsText={t("general.cityAutocomplete")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={t("forms.currency")}
              />
            )}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option?.label}
              </Box>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField
          type={"number"}
          required
          fullWidth
          label={t("forms.salePrice")}
          variant="standard"
          value={values.price}
          onChange={handleChange}
          name="price"
          error={errors.price ?? false}
          helperText={errors.price && <div>{t(errors.price)}</div>}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          type={"number"}
          required
          fullWidth
          label={t("forms.administrationPrice")}
          variant="standard"
          value={values.adminValue}
          onChange={handleChange}
          name="adminValue"
          error={errors.adminValue ?? false}
          helperText={errors.adminValue && <div>{t(errors.adminValue)}</div>}
        />
      </Grid>

      <Grid item xs={4}>
        <FormControl
          required
          component="fieldset"
          onChange={handleChange}
          error={errors.serviceRoom ?? false}
          helperText={errors.serviceRoom && <div>{t(errors.serviceRoom)}</div>}
        >
          <FormControlLabel
            value={values.serviceRoom}
            checked={values.serviceRoom}
            control={<Checkbox />}
            name="serviceRoom"
            label={t("property.serviceRoom")}
            labelPlacement="end"
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          required
          component="fieldset"
          onChange={handleChange}
          error={errors.utilityRoom ?? false}
          helperText={errors.utilityRoom && <div>{t(errors.utilityRoom)}</div>}
        >
          <FormControlLabel
            value={values.utilityRoom}
            checked={values.utilityRoom}
            control={<Checkbox />}
            name="utilityRoom"
            label={t("property.utilityRoom")}
            labelPlacement="end"
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl
          required
          component="fieldset"
          onChange={handleChange}
          error={errors.showOwner ?? false}
          helperText={errors.showOwner && <div>{t(errors.showOwner)}</div>}
        >
          <FormControlLabel
            value={values.showOwner}
            checked={values.showOwner}
            control={<Checkbox />}
            name="showOwner"
            label={t("property.showOwner")}
            labelPlacement="end"
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          required
          component="fieldset"
          onChange={handleChange}
          error={errors.showSeller ?? false}
          helperText={errors.showSeller && <div>{t(errors.showSeller)}</div>}
        >
          <FormControlLabel
            value={values.showSeller}
            checked={values.showSeller}
            control={<Checkbox />}
            name="showSeller"
            label={t("property.showSeller")}
            labelPlacement="end"
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          required
          component="fieldset"
          onChange={handleChange}
          error={errors.showSellerContact ?? false}
          helperText={
            errors.showSellerContact && <div>{t(errors.showSellerContact)}</div>
          }
        >
          <FormControlLabel
            value={values.showSellerContact}
            checked={values.showSellerContact}
            control={<Checkbox />}
            name="showSellerContact"
            label={t("property.showSellerContact")}
            labelPlacement="end"
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          multiline
          rows={4}
          label={t("forms.generalDescription")}
          variant="standard"
          value={values.description}
          onChange={handleChange}
          name="description"
          error={errors.description ?? false}
          helperText={errors.description && <div>{t(errors.description)}</div>}
        />
      </Grid>

      <Grid item xs={12} textAlign={"center"}>
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          sx={{ mb: 3 }}
          disabled={isLoadingUpdate}
        >
          {typeForm === "Create" ? t("forms.create") : t("forms.update")}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default Information;
