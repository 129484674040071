import { useState } from 'react';
import { useQuery } from 'react-query';
import { getCityByText } from 'services/location';

const UseCities = () => {
    const [cityText, setCityText] = useState('');

    const { data: cities } = useQuery({
        queryKey: "getCities",
        enabled: cityText.length > 2,
        queryFn: () => { return getCityByText(cityText) }
    })

    return {
        cities,
        setCityText
    }
}

export default UseCities;
