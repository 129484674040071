import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { getResourceType } from "services/property";
import { postFile } from "services/upload";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const useCreateResources = (values) => {
  const [images, setImages] = useState([]);
  const params = useParams();
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { data: resourceType } = useQuery({
    queryKey: ["getResourceType"],
    queryFn: getResourceType,
  });

  useEffect(() => {
    let fileReaders = [],
      isCancel = false;
    const images = [];
    if (values?.file?.length) {
      Object.keys(values.file).forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
          }
          if (images.length === values.file.length && !isCancel) {
            setImages(images);
          }
        };
        fileReader.readAsDataURL(values.file[file]);
      });
    }
    return () => {
      isCancel = true;
    };
  }, [values]);

  const upload = (values) => {
    uploadImages({
      files: values.file,
      propertyId: params.id,
      resourceTypeId: values.resourceTypeId,
    });
  };

  const { mutate: uploadImages, isLoading: isLoadingResources } = useMutation({
    mutationFn: postFile,
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: error,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getResources")
      Toast.fire({
        icon: "success",
        title: t("resources.add"),
      });
    },
  });
  return {
    isLoadingResources,
    upload,
    resourceType,
    images,
  };
};

export default useCreateResources;
