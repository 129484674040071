import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import UserFormContent from "./components/userFormContent";

const UserForm = () => {
  const { t } = useTranslation()

  return (
    <JumboCardQuick title={t('title.createUser')}>
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <UserFormContent />
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default UserForm;
