import React from "react";
import UserProfile from "../pages/users/user-profile";
import WallApp from "../pages/users/wall-app";
import Page from "@jumbo/shared/Page";
import UserList from "app/pages/users/list";
import Roles from "app/pages/users/roles";
import UserConfiguration from "app/pages/users/configuration";
import UserDetail from "app/pages/users/detail";
import UserForm from "app/pages/users/form";

const userRoutes = [
    {
        path: "/user/profile",
        element: <Page component={UserProfile} />
    },
    {
        path: "/user/social-wall",
        element: <Page component={WallApp} />
    },
    {
        path: "/users",
        element: <Page component={UserList} />
    },
    {
        path: "/user/create",
        element: <Page component={UserForm} />
    },
    {
        path: "/user/roles",
        element: <Page component={Roles} />
    },
    {
        path: "/user/configuration/:userId",
        element: <Page component={UserConfiguration} />
    },
    {
        path: "/user/detail/:userId",
        element: <Page component={UserDetail} />
    },
];
export default userRoutes;
