import Page from "@jumbo/shared/Page";
import CreateCustomer from "app/pages/customers/create";
import CustomersList from "app/pages/customers/list";

const customerRoutes = [
    {
        path: "/customer/create",
        element: <Page component={CreateCustomer} />
    },
    {
        path: "/customers",
        element: <Page component={CustomersList} />
    },
];
export default customerRoutes;
