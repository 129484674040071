/* eslint-disable no-unused-vars */
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { delProperty } from "services/property";

const ITEM_HEIGHT = 48;
const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

export default function Actions({ property }) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    /* const deleteProperty = useMutation({
      mutationFn: delProperty,
      onError: (error) => {
        Toast.fire({
          icon: "error",
          title: error,
        });
      },
      onSuccess: () => {
        Toast.fire({
          icon: "success",
          title: "El registro se archivó correctamente",
        });
        queryClient.invalidateQueries("properties");
      },
    }); */

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        handleClose();
        /* navigate("/property/update/" + idProperty + "/" + property.id, {
          state: {
            title: property.property.title,
            code: property.property.code,
            stateTypeId: property.property.stateType.id,
            propertyTypeId: property.property.propertyType.id,
            businessTypeId: property.property.businessType.id,
            price: property.price,
            adminValue: property.adminValue,
            yearConstruction: property.yearConstruction,
            roomsNumber: property.roomsNumber,
            bathroomsNumber: property.bathroomsNumber,
            parkingNumber: property.parkingNumber,
            serviceRoom: property.serviceRoom,
            buildArea: property.buildArea,
            privateArea: property.privateArea,
            groundArea: property.groundArea,
            rentalTime: property.rentalTime,
            description: property.description,
          },
        }); */
    };

    const handleArchive = async () => {
        // deleteProperty.mutate(idProperty);
        handleClose();
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                    },
                }}
            >
                <MenuItem onClick={handleEdit} disableRipple>
                    <ListItemIcon>
                        <EditIcon />
                    </ListItemIcon>
                    <ListItemText>{t("actions.edit")}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleArchive} disableRipple>
                    <ListItemIcon>
                        <ArchiveIcon />
                    </ListItemIcon>
                    <ListItemText>{t("actions.delete")}</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}
