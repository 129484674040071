export const formatUser = (arr) => {
  return arr?.map((user) => {
    return {
      label: `${user.name} ${user.lastName}`,
      id: user.id,
    };
  });
};

export const formatRoles = (arr) => {
  return arr?.map((user) => {
    return {
      label: `${user.name}`,
      id: user.id,
    };
  });
};

export const buildExportPropertyData = (array) => {
  return array?.map((obj) => {
    const {
      currency,
      city,
      measurement,
      owner,
      seller,
      propertyType,
      businessType,
      stateType,
      parenProperty,
      propertyAmenities,
      images,
      constructionPlans,
      ...rest
    } = obj;

    rest["currency"] = currency.code;
    rest["city"] = city.name;
    rest["measurement"] = measurement.name;
    rest["owner"] = `${owner.name} ${owner.lastName}`;
    rest["seller"] = `${seller.name} ${seller.lastName}`;
    rest["propertyType"] = propertyType.name;
    rest["businessType"] = businessType.name;
    rest["stateType"] = stateType.name;
    rest["parentProperty"] = parenProperty?.title ? parenProperty.title : "No tiene un inmueble padre";
    rest["propertyAmenities"] = propertyAmenities.length === 0 ? "No tiene comodidades" : propertyAmenities.map(item => {
        return `${item.name}`
    }).toString();
    rest["images"] = images.length === 0 ? "No tiene imágenes" : images.map(item => {
        return `${item.path}`
    }).toString();
    rest["constructionPlans"] = constructionPlans.length === 0 ? "No tiene planos" : constructionPlans.map(item => {
        return `${item.path}`
    }).toString();

    return { ...rest };
  });
};

export const generalFormat = (arr) => {
  return arr?.map((item) => {
    return {
      label: `${item.name}`,
      id: item.id
    };
  });
};
