import { useMutation, useQueryClient } from 'react-query';
import { postAddress } from 'services/user';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const UseAddressForm = () => {
    const queryClient = useQueryClient()

    const { mutate: createAddress, isLoading } = useMutation({
        mutationFn: postAddress,
        onSuccess: () => {
            queryClient.invalidateQueries("getAddress")
            Toast.fire({
                icon: "success",
                title: "El registro se creó correctamente",
            });
        },
        onError: (error) => {
            Toast.fire({
              icon: "error",
              title: error,
            });
          },
    })

    return {
        createAddress,
        isLoading
    }
}

export default UseAddressForm;
