import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsFormContent from '../components/contactUsFormContent';

const ContactUsForm = () => {
    const { t } = useTranslation()

    return (
        <JumboCardQuick title={t('title.form')}>
            <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                    <ContactUsFormContent />
                </Grid>
            </Grid>
        </JumboCardQuick>
    );
}

export default ContactUsForm;
