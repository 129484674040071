import Actions from "app/pages/property/list/components/Actions";

export const propertyColumns = (t) => {
  return [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "title",
      headerName: t("forms.title"),
      editable: false,
      width: 200,
    },
    {
      field: "code",
      headerName: t("forms.code"),
      editable: false,
      width: 200,
    },
    {
      field: ["name"],
      headerName: t("cities.city"),
      editable: false,
      width: 200,
      renderCell: (params) => {
        return params.row.city.name;
      },
    },
    {
      field: ["state.name"],
      headerName: t("cities.state"),
      editable: false,
      width: 200,
      renderCell: (params) => {
        return params.row.city.state.name;
      },
    },
    {
      field: "country",
      headerName: t("cities.country"),
      editable: false,
      width: 200,
      renderCell: (params) => {
        return params.row.city.state.country.name;
      },
    },
    {
      field: "price",
      headerName: t("forms.salePrice"),
      editable: false,
      width: 250,
      renderCell: (params) => {
        return `${params.row.price} ${params.row.currency.code}`
      },
    },
    {
      field: "propertyType",
      headerName: t("forms.propertyType"),
      editable: false,
      width: 200,
      renderCell: (params) => {
        return `${params.row.propertyType.name}`
      },
    },
    {
      field: "businessType",
      headerName: t("forms.businessType"),
      editable: false,
      width: 200,
      renderCell: (params) => {
        return `${params.row.businessType.name}`
      },
    },
    {
      field: "buildArea",
      headerName: t("forms.buildArea"),
      editable: false,
      width: 150,
      renderCell: (params) => {
        return `${params.row.buildArea} ${params.row.measurement.code}`
      },
    },
    {
      field: "privateArea",
      headerName: t("forms.privateArea"),
      editable: false,
      width: 150,
      renderCell: (params) => {
        return `${params.row.privateArea} ${params.row.measurement.code}`
      },
    },
    {
      field: "groundArea",
      headerName: t("forms.groundArea"),
      editable: false,
      width: 150,
      renderCell: (params) => {
        return `${params.row.groundArea} ${params.row.measurement.code}`
      },
    },
    {
      field: "roomsNumber",
      headerName: t("forms.rooms"),
      editable: false,
      width: 100
    },
    {
      field: "bathroomsNumber",
      headerName: t("forms.bathrooms"),
      editable: false,
      width: 100
    },
    {
      field: "parkingNumber",
      headerName: t("forms.parking"),
      editable: false,
      width: 130
    },
    {
      field: "seller",
      headerName: t("property.seller"),
      editable: false,
      width: 130,
      renderCell: (params) => {
        return `${params.row.seller.name} ${params.row.seller.lastName}`
      },
    },
    {
      field: "serviceRoom",
      headerName: t("forms.roomService"),
      editable: false,
      width: 130,
      renderCell: (params) => {
        return `${params.row.serviceRoom === true ? t("general.yes") : t("general.no")}`
      },
    },
    {
      field: "utilityRoom",
      headerName: t("forms.utilityRoom"),
      editable: false,
      width: 130,
      renderCell: (params) => {
        return `${params.row.utilityRoom === true ? t("general.yes") : t("general.no")}`
      },
    },
    {
      field: "actions",
      headerName: t("general.actions"),
      type: "string",
      editable: false,
      width: 120,
      renderCell: () => {
        return <Actions />;
      },
    },
  ];
};
