import { Grid, MenuItem, TextField } from '@mui/material';
import React from 'react';

const PersonalDataForm = ({ handleChange, values, errors, setFieldValue, validateField }) => {
    return (
        <Grid
            container
            spacing={4}
            style={{ width: "100%" }}
            justifyContent={"left"}
            alignItems={"left"}
        >
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    label={'Name'}
                    name="name"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('name') }}
                    value={values.name}
                    error={errors.name ?? false}
                    helperText={errors.name ?? ''}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    label={'Last Name'}
                    name="lastName"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('lastName') }}
                    value={values.lastName}
                    error={errors.lastName ?? false}
                    helperText={errors.lastName ?? ''}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    label={'N° de identificación'}
                    name="document"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('document') }}
                    value={values.document}
                    error={errors.document ?? false}
                    helperText={errors.document ?? ''}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    label={'Email'}
                    name="email"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('email') }}
                    value={values.email}
                    error={errors.email ?? false}
                    helperText={errors.email ?? ''}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    type={'number'}
                    label={'Télefono fijo'}
                    name="phoneNumber"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('phoneNumber') }}
                    value={values.phoneNumber}
                    error={errors.phoneNumber ?? false}
                    helperText={errors.phoneNumber ?? ''}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    type={'number'}
                    label={'Télefono móvil'}
                    name="phoneNumber2"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('phoneNumber2') }}
                    value={values.phoneNumber2}
                    error={errors.phoneNumber2 ?? false}
                    helperText={errors.phoneNumber2 ?? ''}
                />
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Estado"
                    defaultValue="EUR"
                    variant="standard"
                    name="state"
                    fullWidth
                    error={errors.state ?? false}
                    helperText={errors.state ?? ''}
                    onChange={handleChange}
                    value={values.state}
                    onBlur={() => { validateField('state') }}
                >
                    {[{
                        value: 1,
                        label: 'Nuevo',
                    },
                    {
                        value: 0,
                        label: "Viejo",
                    }].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    multiline
                    maxRows={5}
                    label={'Datos adicionales del cliente'}
                    name="description"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('description') }}
                    value={values.description}
                    error={errors.description ?? false}
                    helperText={errors.description ?? ''}
                />
            </Grid>
        </Grid>

    );
}

export default PersonalDataForm;
