import { Grid } from "@mui/material";
import Assign from "./components/Assign";

const Permissions = () => {
  return (
    <Grid container spacing={2}>
      <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} sm={12}>
        <Assign />
      </Grid>
    </Grid>
  );
};

export default Permissions;
