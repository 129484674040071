import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Button, Grid, Stack } from "@mui/material";
import useGetProperty from "app/hooks/property/useGetProperty";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import List from "./components/List";

const Preview = () => {
  const { t } = useTranslation();

  const { properties } = useGetProperty(t);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3.75}>
        <Grid item xs={12} lg={12}>
          <JumboCardQuick>
            <Stack spacing={2} direction="row" justifyContent={"center"}>
              <Link to={"/property/create"} style={{ textDecoration: "none" }}>
                <Button variant="contained"> {t("forms.create")}</Button>
              </Link>
            </Stack>
          </JumboCardQuick>
        </Grid>
        <Grid item xs={12} lg={12}>
          <List properties={properties}/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Preview;
