import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { restore } from "services/password";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const useRestorePassword = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const initialValues = { code: "", password: "", confirmPassword: "" };

  const restorePass = useMutation({
    mutationFn: restore,
    onSuccess: () => {
      localStorage.removeItem("userForgotPassword");
      navigation(`/user/login`);
      Toast.fire({
        icon: "success",
        title: t("resetPassword.success"),
      });
    },
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: t("resetPassword.invalidCode"),
      });
    },
  });

  const onSubmit = (values) => {
    const userRestore = JSON.parse(localStorage.getItem("userForgotPassword"));
    const payload = {
      userId: userRestore[0]?.userId,
      verificationCode: values.code,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };

    restorePass.mutate(payload);
  };

  return {
    onSubmit,
    initialValues,
    restorePass
  };
};

export default useRestorePassword;
