import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import useModules from "app/hooks/useModules";
import { useTranslation } from "react-i18next";

const Assign = () => {
  const { t } = useTranslation();
  const {
    modules,
    checked,
    handleChangeFather,
    handleChangeChildren,
    isLoading,
    assignPermissions,
    action
  } = useModules();

  const children = (item) => (
    <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
      <FormControlLabel
        label={item.name}
        control={
          <Checkbox
            key={item.id}
            checked={checked.includes(item.id)}
            onChange={(event) =>
              handleChangeChildren(event.target.checked, item.id, item.fatherId)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        }
      />
    </Box>
  );

  return (
    <Box>
      {modules?.length > 0 &&
        modules.map((item) => (
          <>
            <FormControlLabel
              label={item.name}
              control={
                <Checkbox
                  checked={checked.includes(item.id)}
                  indeterminate={checked.includes(item.id)}
                  onChange={(event) =>
                    handleChangeFather(item.id, event.target.checked)
                  }
                />
              }
            />
            {item.submodule.map((subitem) => children(subitem))}
            <br />
          </>
        ))}
      <Grid item xs={12} textAlign={"center"}>
        <LoadingButton
          variant="contained"
          size="large"
          sx={{ mb: 3 }}
          loading={isLoading}
          disabled={checked.length === 0}
          onClick={assignPermissions}
        >
          {t(`forms.${action}`)}
        </LoadingButton>
      </Grid>
    </Box>
  );
};

export default Assign;
