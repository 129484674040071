import { LoadingButton, TabContext, TabList } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { CreateCustomerSchema } from "app/utils/schemas/customer";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const TabContextForms = ({
  onSubmit,
  value,
  setValue,
  tabForms,
  tabData,
  isLoading,
  user,
}) => {
  const initialValues = {
    name: user?.name ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
    password: "",
    roleId: user?.role.id ?? "",
  };
  const { t } = useTranslation();
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }} paddingX={5}>
      <Formik
        initialValues={initialValues}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={CreateCustomerSchema(t)}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleChange, errors, values, setFieldValue, validateField }) => (
          <Form autoComplete="off" noValidate>
            <Grid
              container
              spacing={4}
              style={{ width: "100%" }}
              justifyContent={"left"}
              alignItems={"left"}
            >
              <TabContext value={value}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: "100%",
                  }}
                >
                  <TabList onChange={handleChangeTab} centered>
                    {tabData.map((tab) => (
                      <Tab label={t(tab.label)} value={tab.value} />
                    ))}
                  </TabList>
                </Box>

                {tabForms(
                  handleChange,
                  errors,
                  values,
                  setFieldValue,
                  validateField
                )}
              </TabContext>

              <Grid item xs={12} textAlign={"center"}>
                {!user && (
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isLoading}
                  >
                    {t("forms.create")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TabContextForms;
