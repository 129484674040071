export const addressColumns = (t) => {
  return [
    {
      field: "address1",
      headerName: `${t("forms.address")} 1`,
      width: 150,
      editable: false,
    },
    {
      field: "address2",
      headerName: `${t("forms.address")} 2`,
      width: 150,
      editable: false,
    },
    {
      field: "city.name",
      headerName: `${t("cities.city")}`,
      width: 150,
      editable: false,
      renderCell: (params) => {
        return params.row.city.name;
      },
    },
    {
      field: "city.state.name",
      headerName: `${t("cities.state")}`,
      width: 150,
      editable: false,
      renderCell: (params) => {
        return params.row.city.state.name;
      },
    },
    {
      field: "city.state.country.name",
      headerName: `${t("cities.country")}`,
      width: 150,
      editable: false,
      renderCell: (params) => {
        return params.row.city.state.country.name;
      },
    },
    {
      field: "phone",
      headerName: t("forms.phone"),
      width: 150,
      editable: false,
    },
    {
      field: "createdBy",
      headerName: t("general.createdBy"),
      width: 150,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("general.actions"),
      type: "string",
      editable: false,
      width: 150,
    },
  ];
};
