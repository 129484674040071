import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "services/modules";
import { getByUser } from "services/permissions";
import usePermissions from "./user/usePermissions";

const useModules = () => {
  const { userId } = useParams();
  const { assign, isLoading } = usePermissions();
  const [checked, setChecked] = useState([]);
  const [action, setAction] = useState("create");

  const { data: permissions } = useQuery({
    queryKey: ["permissions", userId],
    queryFn: async () => {
      const response = await getByUser({ userId });
      if(response.length > 0) {
        setChecked(response)
        setAction("update");
      }
      return response
    },
  });

  const { data: modules } = useQuery({
    queryKey: "modules",
    queryFn: get,
  });

  const handleChangeFather = (id, isChecked) => {
    if (isChecked) {
      const newFatherChecked = modules.filter((item) => item.id === id);
      const newChildren = newFatherChecked[0].submodule.map((item) => {
        return item.id;
      });
      return setChecked([...checked, id, ...newChildren]);
    } else {
      const searchFatherChecked = modules.filter((item) => item.id === id);
      const searchChildren = searchFatherChecked[0].submodule.map((item) => {
        return item.id;
      });

      const newChecked = [];

      for (const item of checked) {
        if (!searchChildren.includes(item)) {
          newChecked.push(item);
        }
      }
      const deleteFather = newChecked.filter((item) => item !== id);
      return setChecked([...deleteFather]);
    }
  };

  const handleChangeChildren = (isChecked, id, fatherId) => {
    const index = checked.indexOf(id);
    if (isChecked) {
      const fatherChecked = modules.filter((item) => item.id === fatherId)[0]
        .id;
      if (checked.includes(fatherChecked)) {
        return setChecked((state) => [...state, id]);
      } else {
        return setChecked((state) => [...state, id, fatherChecked]);
      }
    } else {
      setChecked((state) => {
        state.splice(index, 1);
        return JSON.parse(JSON.stringify(state));
      });
      const newFatherChecked = modules.filter((item) => item.id === fatherId);
      const newChildren = newFatherChecked[0].submodule.map((item) => {
        return item.id;
      });
      const newChecked = [];

      for (const item of checked) {
        if (newChildren.includes(item)) {
          newChecked.push(item);
        }
      }

      if (newChecked.length === 0) {
        const deleteFather = checked.filter((item) => item !== fatherId);
        setChecked([...deleteFather]);
      }
    }
  };

  const assignPermissions = () => {
    const userRV = JSON.parse(localStorage.getItem("userRV"));
    assign({ checked, createdBy: userRV.email, userId });
  };

  return {
    modules,
    checked,
    handleChangeFather,
    handleChangeChildren,
    assignPermissions,
    isLoading,
    permissions,
    action
  };
};

export default useModules;
