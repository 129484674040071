import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Grid } from '@mui/material';
import TabContextGeneral from 'app/shared/TabContextGeneral';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AgencyDataForm from '../form/components/agencyForm';
import SubAgency from './components/subAgency';

const AgencyDetail = () => {
    const { state: agency } = useLocation()
    // const { agency, isLoading } = UseAgencyDetail()

    // if (isLoading) { return <Loading /> }

    const viewForm = () => {
        return <AgencyDataForm data={agency} />
    }

    return (
        <JumboCardQuick>
            <Grid container paddingY={2}>
                <Grid item xs={12}>
                    <TabContextGeneral title={"sidebar.menuItem.agency"} content={viewForm} />
                    <SubAgency />
                </Grid>
            </Grid>
        </JumboCardQuick>
    );
}

export default AgencyDetail;
