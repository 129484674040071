import Alerts from "app/shared/Alerts";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { putProperty } from "services/property";

const useUpdateProperty = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { success } = Alerts();

  const { mutate: update, isLoading } = useMutation({
    mutationFn: putProperty,
    onSuccess: async () => {
      await queryClient.invalidateQueries("getPropertiesReport");
      success("", t("property.deleteResponse"), "success");
    },
  });

  return {
    update,
    isLoading,
  };
};

export default useUpdateProperty;