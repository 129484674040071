import hydraAPI from "services/config/hydraAPI";

export const forgot = async (email) => {
    const result = await hydraAPI().post("password/forgot", email);
    return result.data;
}

export const restore = async (data) => {
    const result = await hydraAPI().post("password/restore", data);
    return result.data;
}