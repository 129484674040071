import hydraAPI from "./config/hydraAPI";

export const postFile = async ({ files, propertyId, resourceTypeId }) => {
  const formData = new FormData();
  /**[
  {
    fieldname: 'files',
    originalname: 'Modelo.JPG',
    encoding: '7bit',
    mimetype: 'image/jpeg',
    destination: './src/uploads/',
    filename: 'Modelo.JPG',
    path: 'src\\uploads\\Modelo.JPG',
    size: 175057
  }
] */
  Array.from(files).forEach(async (file) => {
    let newItem = new File([file], file.name, {
      type: file.type,
    });
    formData.append("files", newItem);
  });
  formData.append("propertyId", propertyId);
  formData.append("resourceTypeId", resourceTypeId);
  const result = await hydraAPI().post("upload/file", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result.data;
};
