import { USE_IMAGE_PLACEHOLDERS } from "./constants/paths";

export const getAssetPath = (url, size) => {
  if (USE_IMAGE_PLACEHOLDERS) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const validateBusinessTypeMsg = (businessType, t) => {
  switch (businessType.id) {
    case 2:
      return t("property.sell");

    case 3:
      return t("property.sellRent");

    default:
      return businessType.label;
  }
};

export const validateShowComponent = (user, ROLES_ID, userRelationComponent, values) => {
  if (user?.role.id === ROLES_ID.ADMIN) {
    if (values.roleId === ROLES_ID.SUB_AGENCIA) {
      return userRelationComponent;
    } else if (values.roleId === ROLES_ID.ASESOR) {
      return userRelationComponent;
    } else if (values.roleId === ROLES_ID.CLIENTE) {
      return userRelationComponent;
    } else {
      return "";
    }
  } else if (user?.role.id === ROLES_ID.AGENCIA) {
    if (values.roleId === ROLES_ID.ASESOR) {
      return userRelationComponent;
    } else if (values.roleId === ROLES_ID.CLIENTE) {
      return userRelationComponent;
    } else {
      return "";
    }
  } else if (user?.role.id === ROLES_ID.SUB_AGENCIA) {
    if (values.roleId === ROLES_ID.CLIENTE) {
      return userRelationComponent;
    } else {
      return "";
    }
  } else {
      return ""
  }
};
