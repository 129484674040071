import React from "react";
import Page from "@jumbo/shared/Page";
import AgencyForm from "app/pages/agency/form";
import AgencyList from "app/pages/agency/list";
import AgencyDetail from "app/pages/agency/detail";

const agencyRoutes = [
    {
        path: "/agency/create",
        element: <Page component={AgencyForm} />
    },
    {
        path: "/agency/create/:userId",
        element: <Page component={AgencyForm} />
    },
    {
        path: "/agency/list",
        element: <Page component={AgencyList} />
    },
    {
        path: "/agency/detail/:agencyId",
        element: <Page component={AgencyDetail} />
    },
];
export default agencyRoutes;
