import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export const citiesColumns = (t) => {
    return  [
        { field: "id", headerName: "ID", width: 90 },
        {
            field: "name",
            headerName: t("cities.city"),
            editable: false,
            width: 200,
        },
        {
            field: ["state.name"],
            headerName: t("cities.state"),
            editable: false,
            width: 200,
            renderCell: (params) => {
                return params.row.state.name
            }
        },
        {
            field: ["state.country.name"],
            headerName: t("cities.country"),
            editable: false,
            width: 200,
            renderCell: (params) => {
                return params.row.state.country.name
            }
        },
        {
            field: "latitude",
            headerName: t("cities.latitude"),
            width: 150,
            editable: false,
        },
        {
            field: "longitude",
            headerName: t("cities.longitude"),
            width: 150,
            editable: false,
        },
        {
            field: "active",
            headerName: t("general.active"),
            editable: false,
            width: 80,
            renderCell: (params) => {
                return params.value ? <CheckCircleOutlineIcon /> : <CancelIcon />
            }
        },
        {
            field: "actions",
            headerName: t("general.actions"),
            type: "string",
            editable: false,
            width: 170,
            /* renderCell: (params) => {
                return <Actions />;
            }, */
        },
    ];
}