import React from "react";
import Page from "@jumbo/shared/Page";
import Amenties from "app/pages/configuration/amenties";
import City from "app/pages/configuration/city";
import Currency from "app/pages/configuration/currency";
import PropertyType from "app/pages/configuration/propertyType";
import Home from "app/pages/home";

const configRoutes = [
    {
        path: "/home",
        element: <Page component={Home} />
    },
    {
        path: "/configuration/amenties",
        element: <Page component={Amenties} />
    },
    {
        path: "/configuration/city",
        element: <Page component={City} />
    },
    {
        path: "/configuration/currency",
        element: <Page component={Currency} />
    },
    {
        path: "/configuration/property-type",
        element: <Page component={PropertyType} />
    },
];
export default configRoutes;
