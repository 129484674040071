import { Grid, MenuItem, TextField } from '@mui/material';
import React from 'react';

const LocationForm = ({ handleChange, values, errors, validateField }) => {
    return (
        <Grid
            container
            spacing={4}
            style={{ width: "100%" }}
            justifyContent={"left"}
            alignItems={"left"}
        >
            <Grid item xs={12} lg={6}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="País"
                    defaultValue="EUR"
                    variant="standard"
                    name="countryId"
                    fullWidth
                    error={errors.countryId ?? false}
                    helperText={errors.countryId ?? ''}
                    onChange={handleChange}
                    onBlur={() => { validateField('countryId') }}
                    value={values.countryId}
                >
                    {[{
                        value: 1,
                        label: 'Nuevo',
                    },
                    {
                        value: 0,
                        label: "Viejo",
                    }].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Estado / Provincia"
                    defaultValue="EUR"
                    variant="standard"
                    name="stateId"
                    fullWidth
                    error={errors.stateId ?? false}
                    helperText={errors.stateId ?? ''}
                    onChange={handleChange}
                    onBlur={() => { validateField('stateId') }}
                    value={values.stateId}
                >
                    {[{
                        value: 1,
                        label: 'Nuevo',
                    },
                    {
                        value: 0,
                        label: "Viejo",
                    }].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Ciudad"
                    defaultValue="EUR"
                    variant="standard"
                    name="cityId"
                    fullWidth
                    error={errors.cityId ?? false}
                    helperText={errors.cityId ?? ''}
                    onChange={handleChange}
                    onBlur={() => { validateField('cityId') }}
                    value={values.cityId}
                >
                    {[{
                        value: 1,
                        label: 'Nuevo',
                    },
                    {
                        value: 0,
                        label: "Viejo",
                    }].map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} lg={6}>
                <TextField
                    required
                    fullWidth
                    label={'Dirección'}
                    name="address"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => { validateField('address') }}
                    value={values.address}
                    error={errors.address ?? false}
                    helperText={errors.address ?? ''}
                />
            </Grid>
        </Grid>

    );
}

export default LocationForm;
