import React from "react";
import { UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import useCreateResources from "app/hooks/resources/useCreateResources";

const MediaForm = ({ t, values, setFieldValue }) => {
  const { resourceType, images, upload, isLoadingResources } = useCreateResources(values)
  return (
    <Grid
      container
      spacing={4}
      style={{ width: "100%" }}
      justifyContent={"left"}
      alignItems={"left"}
    >
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <FormControl required fullWidth variant="standard">
          <InputLabel id="demo-simple-select-standard-label">
            {t("forms.resourceType")}
          </InputLabel>
          <Select
            value={values.resourceTypeId}
            onChange={(e) => setFieldValue("resourceTypeId", e.target.value)}
            name="resourceTypeId"
            defaultValue={values.resourceTypeId}
          >
            {resourceType?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            onChange={(e) => setFieldValue("file", e.target.files)}
          />
          {images.length > 0 ? (
            <Carousel
              width={300}
              showArrows
              showStatus
              autoPlay
              infiniteLoop
              showThumbs={false}
              verticalSwipe={"natural"}
            >
              {images.map((item, index) => (
                <Avatar
                  src={item}
                  variant={"rounded"}
                  key={index}
                  sx={{
                    width: 300,
                    height: 300,
                  }}
                />
              ))}
            </Carousel>
          ) : (
            <UploadFile />
          )}
        </IconButton>
      </Grid>

      <Grid item xs={12} textAlign={"center"}>
        <LoadingButton
          onClick={() => upload(values)}
          type="submit"
          variant="contained"
          size="large"
          sx={{ mb: 3 }}
          loading={isLoadingResources}
        >
          {t("forms.upload")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default MediaForm;
