import { UploadFile } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Avatar, FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Carousel } from "react-responsive-carousel";
import { getResourceType } from "services/property";

const Media = ({ t, values, setFieldValue, uploadImages, propertyId, isLoadingResources }) => {
  const [images, setImages] = useState([]);
  const [resourceTypeId, setResourceTypeId] = useState(1)

  const handleChange = (e) => {
    setFieldValue("file", e.target.files);
  };

  const { data: resourceType } = useQuery({
    queryKey: ['getResourceType'],
    queryFn: getResourceType
  })

  useEffect(() => {
    let fileReaders = [],
      isCancel = false;
    const images = [];
    if (values.file?.length) {
      Object.keys(values.file).forEach((file) => {
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            images.push(result);
          }
          if ((images.length === values.file.length) && !isCancel) {
            setImages(images);
          }
        };
        fileReader.readAsDataURL(values.file[file]);
      });
    }
    return () => {
      isCancel = true;
    };
  }, [values]);

  const upload = () => {
    uploadImages({ files: values.file, propertyId, resourceTypeId });
  };

  return (
    <>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <FormControl
          required
          fullWidth
          variant="standard"
        >
          <InputLabel id="demo-simple-select-standard-label">
            {t('forms.resourceType')}
          </InputLabel>
          <Select
            value={values.resourceTypeId}
            onChange={(e) => setResourceTypeId(e.target.value)}
            name="resourceTypeId"
            defaultValue={resourceType ? resourceType[0].id : 0}
          >
            {
              resourceType?.map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            onChange={handleChange}
          />
          {images.length > 0 ? (
            <Carousel
              width={300}
              showArrows
              showStatus
              showThumbs={false}
              verticalSwipe={"natural"}
            >
              {images.map((item, index) => (
                <Avatar
                  src={item}
                  variant={"rounded"}
                  key={index}
                  sx={{
                    width: 300,
                    height: 300,
                  }}
                />
              ))}
            </Carousel>
          ) : (
            <UploadFile />
          )}
        </IconButton>
      </Grid>

      <Grid item xs={12} textAlign={"center"}>
        <LoadingButton
          onClick={upload}
          variant="contained"
          size="large"
          sx={{ mb: 3 }}
          loading={isLoadingResources}
        >
          {t("forms.upload")}
        </LoadingButton>
      </Grid>
    </>
  );
};

export default Media;
