export const reqCreateProperty = (data) => {
    const userRV = JSON.parse(localStorage.getItem('userRV'))
    const {
        title,
        city,
        stateType,
        propertyType,
        measurement,
        buildArea,
        privateArea,
        groundArea,
        yearConstruction,
        roomsNumber,
        bathroomsNumber,
        parkingNumber,
        owner,
        seller,
        businessType,
        rentalTime,
        currency,
        price,
        adminValue,
        serviceRoom,
        utilityRoom,
        showOwner,
        showSeller,
        showSellerContact,
        description,
    } = data;

    return {
        title,
        propertyTypeId: propertyType.id,
        businessTypeId: businessType.id,
        cityId: city.id,
        stateTypeId: stateType.id,
        createdBy: userRV.email,
        currencyId: currency.id,
        description,
        latitude: "10,2120786",
        longitude: "-67,5615057",
        measurementId: measurement.id,
        ownerId: owner.id, 
        price: Number(price),
        rentalTime: rentalTime || 0,
        buildArea: parseFloat(buildArea),
        privateArea: parseFloat(privateArea),
        groundArea: parseFloat(groundArea),
        adminValue,
        roomsNumber: Number(roomsNumber),
        sellerId: seller.id, // get users
        bathroomsNumber: Number(bathroomsNumber),
        parkingNumber: Number(parkingNumber),
        serviceRoom,
        yearConstruction,
        showOwner,
        showSeller,
        showSellerContact,
        utilityRoom
    }
}