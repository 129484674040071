import React from "react";
import Page from "@jumbo/shared/Page";
import Create from "app/pages/property/create";
import LoadInventory from "app/pages/property/inventory/upload";
import PropertyDetailPreview from "app/pages/property/detail-preview";
import Update from "app/pages/property/update/Information";
import PropertyReport from "app/pages/property/report";
import Report from "app/pages/property/list";
import Preview from "app/pages/property/preview";
import PropertyDetail from "app/pages/property/detail";

const propiertyRoutes = [
  {
    path: "/preview",
    element: <Page component={Preview} />,
  },
  {
    path: "/properties",
    element: <Page component={Report} />,
  },
  {
    path: "/property/create",
    element: <Page component={Create} />,
  },
  {
    path: "/property/inventory/upload",
    element: <Page component={LoadInventory} />,
  },
  {
    path: "/property/detail/:id",
    element: <Page component={PropertyDetail} />,
  },
  {
    path: "/property/detail-preview/:id",
    element: <Page component={PropertyDetailPreview} />,
  },
  {
    path: "/property/update/:propertyId/:detailId",
    element: <Page component={Update} />,
  },
  {
    path: "/property/report",
    element: <Page component={PropertyReport} />,
  },
];
export default propiertyRoutes;
