import Alerts from "app/shared/Alerts";
import { ROLES_ID } from "app/utils/constants/general";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { updateUser } from "services/user";

const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { success } = Alerts();

  const { mutate: update, isLoading } = useMutation({
    mutationFn: updateUser,
    onSuccess: async () => {
      const userRV = JSON.parse(localStorage.getItem("userRV"));
      await queryClient.invalidateQueries(
        userRV.role.id === ROLES_ID.ADMIN
          ? "getUser"
          : "getUserByUserIdAndRolId"
      );
      success("", t("user.deleteResponse"), "success");
    },
  });

  return {
    update,
    isLoading,
  };
};

export default useUpdateUser;
