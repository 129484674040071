import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Grid } from '@mui/material';
import React from 'react';
import CreateForm from './components/createForm';
import ListView from './components/list';

const Roles = () => {
    return (
        <JumboCardQuick title={"Administrador de roles"}>
            <Grid container rowSpacing={1}>
                <Grid item xs={6}>
                    <CreateForm />
                </Grid>
                <Grid item xs={6}>
                    <ListView />
                </Grid>
            </Grid>

        </JumboCardQuick>
    );
}

export default Roles;
