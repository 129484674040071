import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserConfiguration } from 'services/user';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

const AgencyListContent = ({ ViewButtonCreate, isFromDetail }) => {
    const { userId } = useParams()
    const navigate = useNavigate()

    const { data: agenciesByUser, isLoading: isLoadingGetAgenciesByUser } = useQuery({
        queryKey: ["getAgencies", userId],
        enabled: !!userId,
        queryFn: () => getUserConfiguration({ id: userId }),
    })

    const { data: agencies, isLoading: isLoadingGetAgencies } = useQuery({
        queryKey: ["getAgencies"],
        enabled: !isFromDetail,
        queryFn: getUserConfiguration,
    })

    const columns = [
        {
            field: "id",
            headerName: "Id",
            editable: false
        },
        {
            field: "agencyName",
            headerName: "Agency Name",
            editable: false,
            width: 250
        },
        {
            field: "description",
            headerName: "Description",
            editable: false,
            width: 300
        },
        {
            field: "active",
            headerName: "Active",
            editable: false,
            renderCell: (params) => {
                return params.value ? <CheckCircleOutlineIcon /> : <CancelIcon />
            },
            width: 50
        }
    ]

    const handleCellClick = (params) => {
        if (isFromDetail) {
            alert("Agencia seleccionada: " + params.id)
        } else {
            navigate(`/agency/detail/${params.id}`, {
                state: params.row
            })
        }
    }

    return (
        <Box sx={{ height: 400, width: "100%" }}>
            {
                isFromDetail && (
                    ViewButtonCreate()
                )
            }
            {
                /** Acá va el select de agencias solo visible para el administrador */
            }
            <DataGrid
                rows={agenciesByUser ?? agencies ?? []}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                // checkboxSelection
                onCellClick={handleCellClick}
                editRowsModel={false}
                resizable={true}
                loading={isLoadingGetAgenciesByUser || isLoadingGetAgencies}
                experimentalFeatures={{ newEditingApi: true }}
            />
        </Box>
    );
}

export default AgencyListContent;
