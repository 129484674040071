import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getUser } from 'services/user';

const UseUserDetail = () => {
    const { userId } = useParams()
    const { data: user, isLoading } = useQuery({
        queryKey: ["getUser", userId],
        queryFn: async () => {
            const data = await getUser({ userId })
            return data[0]
        }
    })

    return {
        user,
        isLoading
    }
}

export default UseUserDetail;
