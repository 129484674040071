import { Grid } from '@mui/material';
import React from 'react';
import FilterForm from './components/FilterForm';
import ReportList from './components/ReportList';

const Preview = () => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
                <FilterForm />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ReportList />
            </Grid>
        </Grid>
    );
}

export default Preview;
