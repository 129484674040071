
export const initialValuesCreate = {
    title: "",
    code: "",
    city: {
      id: 0,
      label: ""
    },
    stateType: {
      id: 0,
      label: ""
    },
    propertyType: {
      id: 0,
      label: ""
    },
    businessType: {
      id: 0,
      label: ""
    },
    measurement: {
      id: 0,
      label: ""
    },
    owner: {
      id: 0,
      label: ""
    },
    seller: {
      id: 0,
      label: ""
    },
    currency: {
      id: 0,
      label: ""
    },
    price: "",
    adminValue: "",
    yearConstruction: "",
    roomsNumber: "",
    bathroomsNumber: "",
    parkingNumber: "",
    buildArea: "",
    privateArea: "",
    groundArea: "",
    rentalTime: "",
    serviceRoom: false,
    utilityRoom: false,
    showOwner: false,
    showSeller: false,
    showSellerContact: false,
    description: "",
  };