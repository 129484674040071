import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, FormControl, Grid, TextField } from "@mui/material";
import UseAddressForm from "app/hooks/useAddressForm";
import UseCities from "app/hooks/useCities";
import useCountry from "app/hooks/useCountry";
import { CreateAddressSchema } from "app/utils/schemas/address";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const AddressForm = () => {
  const { createAddress, isLoading } = UseAddressForm();
  const { cities, setCityText } = UseCities();
  const { countries } = useCountry();
  const { t } = useTranslation();
  const { userId } = useParams();

  return (
    <Box sx={{ width: "100%" }}>
      <Formik
        initialValues={{
          cityId: "",
          address1: "",
          address2: "",
          zipCode: "",
          phone: "",
          indicative: "",
        }}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={CreateAddressSchema(t)}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          createAddress({ ...values, userId });
          resetForm();
          setSubmitting(false);
        }}
      >
        {({ handleChange, errors, values, validateField, setFieldValue }) => (
          <Form autoComplete="off" noValidate>
            <Grid
              container
              spacing={4}
              style={{ width: "100%" }}
              justifyContent={"left"}
              alignItems={"left"}
            >
              <Grid item xs={12} lg={6}>
                <FormControl
                  required
                  fullWidth
                  variant="standard"
                  onChange={handleChange}
                  error={errors.cityId ?? false}
                  helperText={errors.cityId && <div>{t(errors.cityId)}</div>}
                >
                  <Autocomplete
                    disablePortal
                    options={cities ?? []}
                    onChange={(_, value) => setFieldValue("cityId", value.id)}
                    onKeyUp={(e) => setCityText(e.target.value)}
                    noOptionsText={t("general.cityAutocomplete")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={t("general.city")}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.label}, {option?.state}
                        <br />
                        {option?.country}
                      </Box>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={`${t("forms.address")} 1`}
                  name="address1"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => {
                    validateField("address1");
                  }}
                  value={values.address1}
                  error={errors.address1 ?? false}
                  helperText={errors.address1 ?? ""}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={`${t("forms.address")} 2`}
                  name="address2"
                  variant="standard"
                  onChange={handleChange}
                  placeholder="Apto/casa, torre, residencia"
                  onBlur={() => {
                    validateField("address2");
                  }}
                  value={values.address2}
                  error={errors.address2 ?? false}
                  helperText={errors.address2 ?? ""}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={t("forms.zipCode")}
                  name="zipCode"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => {
                    validateField("zipCode");
                  }}
                  value={values.zipCode}
                  error={errors.zipCode ?? false}
                  helperText={errors.zipCode ?? ""}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FormControl
                  required
                  fullWidth
                  variant="standard"
                  onChange={handleChange}
                  error={errors.indicative ?? false}
                  helperText={
                    errors.indicative && <div>{t(errors.indicative)}</div>
                  }
                >
                  <Autocomplete
                    disablePortal
                    options={countries}
                    onChange={(_, value) => setFieldValue("indicative", value.id)}
                    noOptionsText={t("general.noData")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label={t("forms.indicative")}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        key={option.id}
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option?.label}
                      </Box>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  required
                  fullWidth
                  label={t("forms.phone")}
                  name="phone"
                  variant="standard"
                  onChange={handleChange}
                  onBlur={() => {
                    validateField("phone");
                  }}
                  value={values.phone}
                  error={errors.phone ?? false}
                  helperText={errors.phone ?? ""}
                />
              </Grid>

              <Grid item xs={12} textAlign={"center"}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isLoading}
                >
                  {t("forms.create")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddressForm;
