import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useJumboTheme } from "@jumbo/hooks";
import { Avatar, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from "react-router-dom";
import {
  AspectRatio,
  Bathroom,
  Bed,
  HomeWork,
  LocalParking,
  PersonAddAlt1,
} from "@mui/icons-material";
import ItemDetail from "./components/ItemDetail";
import { useTranslation } from "react-i18next";
import MarkerClustererExample from "./components/Map";
import Div from "@jumbo/shared/Div";
import CustomChip from "./components/CustomChip";

const PropertyDetailPreview = () => {
  const { t } = useTranslation();
  const { theme } = useJumboTheme();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  // const params = useParams();
  const [data, setData] = useState();

  useEffect(() => {
    (() => {
      setData(location.state);
      setIsLoading(false);
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    })();
  }, [location.state]);

  if (isLoading) {
    return (
      <Div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: (theme) => theme.spacing(3),
          m: "auto",
        }}
      >
        <CircularProgress />
        <Typography variant={"h6"} color={"text.secondary"} mt={2}>
          Loading
        </Typography>
      </Div>
    );
  }

  return (
    <JumboCardQuick>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={6}>
          <Grid container alignContent={"space-between"} height="100%">
            <Grid item xs={12}>
              <Grid container justifyContent={"space-between"}>
                <Typography variant={"h4"} mb={1.25}>
                  {data.title}
                </Typography>
                <Typography variant={"h4"} color={"primary"}>
                  $ {data.price}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomChip idType={data.businessType.id} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={"h6"} color={"text.primary"} mb={0.5}>
                {data?.location?.address}
              </Typography>
              <Carousel
                width={"100%"}
                showArrows
                showStatus
                autoPlay
                infiniteLoop
                showThumbs
                verticalSwipe={"natural"}
              >
                {data.images.map((item, index) => (
                  <Avatar
                    src={item.path}
                    variant={"rounded"}
                    key={index}
                    sx={{
                      width: "100%",
                      height: "100%",
                      [theme.breakpoints.down("md")]: {
                        height: 320,
                      },
                    }}
                  />
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="h4" mb={1.25}>
                {t("forms.generalDescription")}
              </Typography>
              <Typography>{data.description}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" mb={1.25}>
                {t("general.propertyDetail")}
              </Typography>
              <Grid container>
                <ItemDetail
                  icon={<Bed />}
                  title={t("forms.numberOfRooms")}
                  description={data.roomsNumber}
                />

                <ItemDetail
                  icon={<Bathroom />}
                  title={t("forms.numberOfBathrooms")}
                  description={data.bathroomsNumber}
                />

                <ItemDetail
                  icon={<LocalParking />}
                  title={t("forms.parkingStall")}
                  description={data.parkingNumber}
                />

                <ItemDetail
                  icon={<AspectRatio />}
                  title="Área"
                  description={data.area}
                />

                <ItemDetail
                  icon={<PersonAddAlt1 />}
                  title={t("forms.roomService")}
                  description={data?.serviceRoom ? "Si" : "No"}
                />

                <ItemDetail
                  icon={<HomeWork />}
                  title={t("forms.propertyType")}
                  description={data?.propertyType.name}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" mb={1.25}>
            {t("general.location")}
          </Typography>
          <Grid container rowSpacing={1}>
            <Grid item>
              <ItemDetail
                title={t("forms.address")}
                description={data?.location?.address}
              />
            </Grid>
            <Grid item>
              <ItemDetail
                title={t("general.city")}
                description={data?.location?.city?.name}
              />
            </Grid>
            <Grid item>
              <ItemDetail
                title={t("general.zone")}
                description={data?.location?.zone}
              />
            </Grid>
          </Grid>
          <MarkerClustererExample
            lat={Number(data?.latitude)}
            lng={Number(data?.longitude)}
          />
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default PropertyDetailPreview;
