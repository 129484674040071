import { Autocomplete, Box, FormControl, Grid, TextField } from "@mui/material";
import useUserRelation from "app/hooks/user/useUserRelation";
import { validateShowComponent } from "app/utils/appHelpers";
import { ROLES_ID } from "app/utils/constants/general";
import { formatUser } from "app/utils/formats";
import React from "react";

const UserRelation = ({ handleChange, errors, values, setFieldValue, t }) => {
  const { user, usersByRol } = useUserRelation(values);

  const userRelationComponent = (
    <Grid item xs={12} lg={6}>
      <FormControl
        required
        fullWidth
        variant="standard"
        onChange={handleChange}
        error={errors.userRelationId ?? false}
        helperText={
          errors.userRelationId && <div>{t(errors.userRelationId)}</div>
        }
      >
        <Autocomplete
          options={formatUser(usersByRol) ?? []}
          onChange={(_, value) => {
            setFieldValue("userRelationId", value.id);
          }}
          noOptionsText={t("general.noData")}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={t("A quién pertenece?")}
            />
          )}
          renderOption={(props, option) => (
            <Box {...props}>{option?.label}</Box>
          )}
        />
      </FormControl>
    </Grid>
  );

  return validateShowComponent(user, ROLES_ID, userRelationComponent, values)
};

export default UserRelation;
