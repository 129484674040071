import { Grid } from '@mui/material';
import CardButton from 'app/layouts/card-button/CardButton';
import ListDataGrid from 'app/layouts/card-datagrid/ListDataGrid';
import { propertyTypeColumns } from 'app/utils/tableColumns/propertyType';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getPropertyTypes } from 'services/configuration';

const PropertyType = () => {

    const { t } = useTranslation()
 
    const { data: rows, isLoading } = useQuery({
        queryKey: "getPropertyTypes",
        queryFn: getPropertyTypes
    })

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
                <CardButton disabled={true} />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ListDataGrid rows={rows} columns={propertyTypeColumns(t)} isLoading={isLoading} />
            </Grid>
        </Grid>
    );
}

export default PropertyType;
