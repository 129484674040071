import { Box, Grid, Typography } from "@mui/material";
import useProperty from "app/hooks/useProperty";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Loading from "app/layouts/shared/loading";
import Information from "./steps/Information";
import Media from "./steps/Media";
import ValidationsSchema from "app/utils/schemas/property";
import { initialValuesCreate } from "app/utils/initialValues/property";

const PropertyForm = ({ step = 1, titleForm, handleNext }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [typeForm, setTypeForm] = useState("Create");
  const [isLoadingDataForm, setIsLoadingDataForm] = useState(true);
  const [initialValues, setInitialValues] = useState(initialValuesCreate);
  const {
    businessType,
    propiertyType,
    stateType,
    onSubmit,
    uploadImages,
    isLoading,
    propertyId,
    measurements,
    isLoadingUpdate,
    isLoadingResources
  } = useProperty(typeForm, params, handleNext);

  const { CreatePropertySchema } = ValidationsSchema();

  useEffect(() => {
    (() => {
      if (location.state) {
        setInitialValues(location.state);
        setTypeForm("Update");
      }
      setIsLoadingDataForm(false);
    })();
  }, [location.state]);

  if (isLoading || isLoadingDataForm) {
    return <Loading />;
  }

  const StepView = ({ handleChange, values, errors, setFieldValue }) => {
    switch (step) {
      case 1:
        return (
          <Information
            t={t}
            handleChange={handleChange}
            values={values}
            errors={errors}
            stateType={stateType}
            propiertyType={propiertyType}
            measurements={measurements}
            setFieldValue={setFieldValue}
            businessType={businessType}
            typeForm={typeForm}
            isLoadingUpdate={isLoadingUpdate}
          />
        );
      case 2:
        return (
          <Media
            t={t}
            values={values}
            setFieldValue={setFieldValue}
            uploadImages={uploadImages}
            propertyId={propertyId}
            isLoadingResources={isLoadingResources}
          />
        );

      default:
        break;
    }
  };

  return (
    <Box sx={{ width: "100%" }} paddingX={5}>
      <Typography variant={"h4"} my={2}>
        {t(`title.${titleForm}`)}
      </Typography>
      <Formik
        initialValues={initialValues}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={CreatePropertySchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleChange, errors, values, setFieldValue }) => (
          <Form autoComplete="off" noValidate>
            <Grid
              container
              spacing={4}
              style={{ width: "100%" }}
              justifyContent={"left"}
              alignItems={"left"}
            >
              <StepView
                handleChange={handleChange}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PropertyForm;
