/* eslint-disable */
import React from "react";
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import { IconButton, useMediaQuery } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import LocalizationOptions from "../../../../shared/JumboCustomizer/components/LocalizationOptions";

const Header = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const [dropdownSearchVisibility, setDropdownSearchVisibility] =
    React.useState(false);
  const { headerTheme } = useJumboHeaderTheme();

  const showDropdownSearch = useMediaQuery("(max-width:575px)");

  return (
    <React.Fragment>
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{
              ml:
                sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                  ? -2
                  : 0,
              mr: 3,
            }}
            onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}
          >
            {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        )}
      {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? "light"} />
      )}
      <Stack
        direction="row"
        alignItems="center"
        spacing={1.25}
        sx={{ ml: "auto" }}
      >
        {showDropdownSearch && (
          <JumboIconButton
            elevation={25}
            onClick={() => setDropdownSearchVisibility(true)}
          >
            <SearchIcon fontSize={"small"} />
          </JumboIconButton>
        )}
        {/*<MessagesDropdown />
        <NotificationsDropdown /> */}
        <LocalizationOptions />
        <AuthUserDropdown />
      </Stack>
    </React.Fragment>
  );
};

export default Header;
