import { Grid } from '@mui/material';
import CardButton from 'app/layouts/card-button/CardButton';
import ListDataGrid from 'app/layouts/card-datagrid/ListDataGrid';
import { amenitiesColumns } from 'app/utils/tableColumns/amenities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getAmenities } from 'services/configuration';


const Amenties = () => {

    const { t } = useTranslation()
    
    const { data: rows, isLoading } = useQuery({
        queryKey: "getAmenities",
        queryFn: getAmenities
    })

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
                <CardButton disabled={true} />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ListDataGrid rows={rows} columns={amenitiesColumns(t)} isLoading={isLoading} />
            </Grid>
        </Grid>
    );
}

export default Amenties;
