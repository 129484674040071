import React from "react";
import { Navigate } from "react-router-dom";
import Login2 from "../pages/auth-pages/login2";
import Signup2 from "../pages/auth-pages/signup2";
import Page from "@jumbo/shared/Page";
import propiertyRoutes from "./propiertyRoutes";
import userRoutes from "./userRoutes";
import customerRoutes from "./customerRoutes";
import agencyRoutes from "./agencyRoute";
import subAgencyRoutes from "./subAgencyRouter";
import configRoutes from "./configRouter";
import contactUsRoutes from "./contactUsRoutes";
import ForgotPassword from "app/pages/auth-pages/forgot-password/ForgotPassword";
import ResetPassword from "app/pages/auth-pages/reset-password/ResetPassword";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
  {
    path: "/",
    element: <Navigate to={"/home"} />,
  },
  ...propiertyRoutes,
  ...userRoutes,
  ...customerRoutes,
  ...agencyRoutes,
  ...subAgencyRoutes,
  ...configRoutes,
  ...contactUsRoutes
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
  {
    path: "/user/login",
    element: (
      <Page component={Login2} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: "/user/signup",
    element: <Signup2 />,
  },
  {
    path: "/user/forgot-password",
    element: (
      <Page component={ForgotPassword} layout={"solo-page"} disableSmLogin={true} />
    ),
  },
  {
    path: "/user/reset-password",
    element: (
      <Page component={ResetPassword} layout={"solo-page"} disableSmLogin={true} />
    ),
  }
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
