import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const ListDataGrid = ({
  rows,
  columns,
  isLoading,
  pageSize = 10,
  height = 650,
}) => {

  return (
    <JumboCardQuick>
      <Box sx={{ height, width: "100%" }}>
        <DataGrid
          rows={rows ? (rows === "" ? [] : rows) : []}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[10]}
          editRowsModel={false}
          resizable={true}
          loading={isLoading}
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
    </JumboCardQuick>
  );
};

export default ListDataGrid;
