import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { forgot } from "services/password";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const useForgotPassword = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const initialValues = { email: "" };

  const sendEmail = useMutation({
    mutationFn: forgot,
    onSuccess: (user) => {
      localStorage.setItem("userForgotPassword", JSON.stringify(user.data));
      navigation(`/user/reset-password`);
    },
    onError: () => {
      Toast.fire({
        icon: "error",
        title: t("forgotPassword.notUser"),
      });
    },
  });

  const onSubmit = (values) => {
    sendEmail.mutate(values);
  };

  return {
    onSubmit,
    initialValues,
    sendEmail
  };
};

export default useForgotPassword;
