import * as yup from "yup";

const ValidationsSchema = () => {
  const CreatePropertySchema = () => {
    const schema = {
      title: yup.string().required("validate.required"),
      price: yup.string().required("validate.required"),
      stateType: yup.object().required("validate.required"),
      propertyType: yup.object().required("validate.required"),
      businessType: yup.object().required("validate.required"),
      adminValue: yup.string().required("validate.required"),
      yearConstruction: yup.string().required("validate.required"),
      roomsNumber: yup.string().required("validate.required"),
      bathroomsNumber: yup.string().required("validate.required"),
      parkingNumber: yup.string().required("validate.required"),
      serviceRoom: yup.string().required("validate.required"),
      buildArea: yup.string().required("validate.required"),
      privateArea: yup.string().required("validate.required"),
      groundArea: yup.string().required("validate.required"),
      description: yup.string().required("validate.required"),
      currency: yup.object().required("validate.required"),
      city: yup.object().required("validate.required")
    };

    return yup.object().shape(schema);
  };

  return {
    CreatePropertySchema
  };
};

export default ValidationsSchema;
