import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export const amenitiesColumns = (t) => {
    return  [
        { field: "id", headerName: "ID", width: 90 },
        {
            field: "name",
            headerName: t("general.name"),
            editable: false,
            width: 300
        },
        {
            field: "isMain",
            headerName: t("amenities.isMain"),
            editable: false,
            width: 200,
            renderCell: (params) => {
                return params.value ? <CheckCircleOutlineIcon /> : <CancelIcon />
            }
        },
        {
            field: "active",
            headerName: t("general.active"),
            editable: false,
            width: 80,
            renderCell: (params) => {
                return params.value ? <CheckCircleOutlineIcon /> : <CancelIcon />
            }
        },
        {
            field: "actions",
            headerName: t("general.actions"),
            type: "string",
            editable: false,
            width: 170
        },
    ];

}