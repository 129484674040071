import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import AgencyDataForm from "./components/agencyForm";

const AgencyForm = () => {
    const { t } = useTranslation()

    return (
        <JumboCardQuick title={t('title.createAgency')}>
            <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                    <AgencyDataForm />
                </Grid>
            </Grid>
        </JumboCardQuick>
    );
};

export default AgencyForm;

