import React from "react";
import Page from "@jumbo/shared/Page";
import ContactUs from "app/pages/contact-us";
import ContactUsForm from "app/pages/contact-us/form";

const contactUsRoutes = [
    {
        path: "/contact-us",
        element: <Page component={ContactUs} />
    },
    {
        path: "/contact-us/create",
        element: <Page component={ContactUsForm} />
    }
];
export default contactUsRoutes;
