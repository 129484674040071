import axios from "axios";

export const hydraAPI = () => {
  let headers = {
    "Accept-Language": "es",
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });

  return instance;
};

export default hydraAPI;
