import { Box, Grid } from "@mui/material";
import useProperty from "app/hooks/useProperty";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Loading from "app/layouts/shared/loading";
import ValidationsSchema from "app/utils/schemas/property";
import Information from "./components/informationForms/Information";

const initValues = {
  title: "",
  code: "",
  stateTypeId: "",
  propertyTypeId: "",
  businessTypeId: "",
  price: "",
  adminValue: "",
  yearConstruction: "",
  roomsNumber: "",
  bathroomsNumber: "",
  parkingNumber: "",
  serviceRoom: "",
  buildArea: "",
  privateArea: "",
  groundArea: "",
  rentalTime: "",
  description: "",
  cityId: "",
  measurementId: "",
  ownerId: "",
  sellerId: "",
  currencyId: "",
};

const InformationForm = ({ step = 1, handleNext }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const [typeForm, setTypeForm] = useState("Create");
  const [isLoadingDataForm, setIsLoadingDataForm] = useState(true);
  const [initialValues, setInitialValues] = useState(initValues);
  const {
    businessType,
    propiertyType,
    stateType,
    onSubmit,
    isLoading,
    measurements,
    isLoadingUpdate
  } = useProperty(typeForm, params, handleNext);

  const { CreatePropertySchema } = ValidationsSchema();

  useEffect(() => {
    (() => {
      if (location.state) {
        const { state } = location;
        setInitialValues({
          ...location.state,
          stateType: {
            id: state.stateType?.id,
            label: state.stateType?.name
          },
          propertyType: {
            id: state.propertyType?.id,
            label: state.propertyType?.name
          },
          businessType: {
            id: state.businessType?.id,
            label: state.businessType?.name
          },
          measurement: {
            id: state.measurement?.id,
            label: state.measurement?.name
          },
          owner: {
            id: state.owner?.id,
            label: `${state.owner?.name} ${state.owner?.lastName}`
          },
          seller: {
            id: state.seller?.id,
            label: `${state.seller?.name} ${state.seller?.lastName}`
          },
          currency: {
            id: state.currency?.id,
            label: state.currency?.name
          },
          price: Number(state.price),
          city: {
            id: state.city?.id,
            label: state.city?.name
          }
        });
        setTypeForm("Update");
      }
      setIsLoadingDataForm(false);
    })();
  }, [location, location.state]);

  if (isLoading || isLoadingDataForm) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: "100%" }} paddingX={5}>
      <Formik
        initialValues={initialValues}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={CreatePropertySchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ handleChange, errors, values, setFieldValue }) => (
          <Form autoComplete="off" noValidate>
            <Grid
              container
              spacing={4}
              style={{ width: "100%" }}
              justifyContent={"left"}
              alignItems={"left"}
            >
              <Information
                t={t}
                handleChange={handleChange}
                values={values}
                errors={errors}
                stateType={stateType}
                propiertyType={propiertyType}
                measurements={measurements}
                setFieldValue={setFieldValue}
                businessType={businessType}
                typeForm={typeForm}
                isLoadingUpdate={isLoadingUpdate}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default InformationForm;
