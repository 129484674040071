import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Media from "../update/Media";
import InformationForm from "../update/Information";

const PropertyDetail = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <JumboCardQuick>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab label={t("title.information")} value="1" />
              <Tab label={t("title.media")} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1"><InformationForm /></TabPanel>
          <TabPanel value="2"><Media /></TabPanel>
        </TabContext>
      </JumboCardQuick>
    </Box>
  );
};

export default PropertyDetail;
