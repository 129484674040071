import hydraAPI from "services/config/hydraAPI";

export const getCities = async () => {
    const result = await hydraAPI().post("city/get")
    return result.data.data;
};

export const getCityByText = async (text) => {
    const result = await hydraAPI().get(`city/getByText/${text}`)
    return result.data.data.map(city => {
        return {
            label: city.name,
            state: city?.state?.name,
            country: city?.state?.country?.name,
            id: city.id
        }
    })
}

export const getCountries = async () => {
    const result = await hydraAPI().post("country/get")
    return result.data.data.map(country => {
        return {
            label: country.name,
            id: country.indicative
        }
    })
};