import React from 'react';
import AddressForm from './components/addressForm';
import AddressList from './components/addressList';

const Address = () => {
    return (
        <div>
            <AddressForm />
            <AddressList />
        </div>
    );
}

export default Address;
