import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const CreateForm = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%" }} paddingX={5}>
      <Typography variant={"h4"} my={2}>
        Crear Rol
      </Typography>
      <Formik
        initialValues={{}}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={{}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {({ handleChange, errors, values, setFieldValue }) => (
          <Form autoComplete="off" noValidate>
            <Grid
              container
              spacing={4}
              style={{ width: "100%" }}
              justifyContent={"left"}
              alignItems={"left"}
            >
              <Grid item xs={12}>
                <FormControlLabel control={<Checkbox />} label="Asignar rol" />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label={"Description"}
                  name="description"
                  variant="standard"
                  onChange={handleChange}
                  value={values.des}
                  error={errors.des ?? false}
                  helperText={errors.des && <div>error</div>}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl
                  required
                  variant="standard"
                  onChange={handleChange}
                  fullWidth
                  error={errors.propertyTypeId ?? false}
                  helperText={
                    errors.propertyTypeId && <div>{t(errors.propertyTypeId)}</div>
                  }
                >
                  <InputLabel>
                    {t("forms.selectRolesPadres")}
                  </InputLabel>
                  <Select
                    value={values.propertyTypeId}
                    onChange={handleChange}
                    name="propertyTypeId"
                  >
                    {[].map((property) => (
                      <MenuItem value={property.id}>{property.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} textAlign={"center"}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                >
                  {t("forms.create")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateForm;
