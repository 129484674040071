import { ROLES_ID } from "app/utils/constants/general";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { getUser, postUser, postUserRelation } from "services/user";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const UseCreateUser = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [codeText, setCodeText] = useState("");
  const [disbabledButton, setDisabledButton] = useState(false);

  const initialValues = {
    name: "",
    lastName: "",
    code: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleId: "",
    userRelationId: "",
  };

  const { mutate: createUserRelation } = useMutation({
    mutationFn: postUserRelation,
  });

  const relateTo = (agencyId, userId) => {
    const req = {
      agencyId,
      userId,
    };
    createUserRelation(req);
  };

  const validateRole = (newUser) => {
    const userRV = JSON.parse(localStorage.getItem("userRV"));
    if (userRV.role.id === ROLES_ID.ADMIN) {
      if (
        newUser.roleId === ROLES_ID.SUB_AGENCIA ||
        newUser.roleId === ROLES_ID.ASESOR ||
        newUser.roleId === ROLES_ID.CLIENTE
      ) {
        relateTo(newUser.userRelationId, newUser.id);
      }
    } else if (userRV.role.id === ROLES_ID.AGENCIA) {
      if (
        newUser.roleId === ROLES_ID.SUB_AGENCIA ||
        newUser.roleId === ROLES_ID.IBO
      ) {
        relateTo(userRV.id, newUser.id);
      } else if (
        newUser.roleId === ROLES_ID.ASESOR ||
        newUser.roleId === ROLES_ID.CLIENTE
      ) {
        relateTo(newUser.userRelationId, newUser.id);
      }
    } else if (userRV.role.id === ROLES_ID.SUB_AGENCIA) {
      if (newUser.roleId === ROLES_ID.ASESOR) {
        relateTo(userRV.id, newUser.id);
      } else if (newUser.roleId === ROLES_ID.CLIENTE) {
        relateTo(newUser.userRelationId, newUser.id);
      }
    } else if (userRV.role.id === ROLES_ID.ASESOR) {
      if (newUser.roleId === ROLES_ID.CLIENTE) {
        relateTo(userRV.id, newUser.id);
      }
    } else if (userRV.role.id === ROLES_ID.IBO) {
      if (newUser.roleId === ROLES_ID.CLIENTE) {
        relateTo(userRV.id, newUser.id);
      }
    }
  };

  const createUser = useMutation({
    mutationFn: postUser,
    onSuccess: (user, variables) => {
      validateRole({
        ...user,
        roleId: variables.roleId,
        userRelationId: variables.userRelationId,
      });
      navigation(`/user/detail/${user.id}`);
    },
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: error,
      });
    },
  });

  useEffect(() => {
    async function getByCode() {
      if (codeText.length > 2 && codeText.length < 11) {
        const response = await getUser({ code: codeText });
        if (response) {
          setDisabledButton(true);
          Toast.fire({
            icon: "error",
            title: t("user.codeValidation"),
          });
        } else {
          setDisabledButton(false);
        }
      }
    }
    getByCode();
  }, [codeText, t]);

  const onSubmit = (values) => {
    createUser.mutate(values);
  };

  return {
    onSubmit,
    isLoading: createUser.isLoading,
    initialValues,
    setCodeText,
    disbabledButton,
  };
};

export default UseCreateUser;
