import { REAL_VISION_CODE, ROLES_ID } from "app/utils/constants/general";

export const associatedFather = (role) => {
    switch (role) {
        case ROLES_ID.SUB_AGENCIA:
            return [ROLES_ID.AGENCIA]
        case ROLES_ID.REALTOR:
            return [ROLES_ID.CLIENTE]
        case ROLES_ID.ASESOR:
            return [ROLES_ID.SUB_AGENCIA]
        case ROLES_ID.CLIENTE:
            return [ROLES_ID.SUB_AGENCIA, ROLES_ID.ASESOR, ROLES_ID.AGENCIA]
        default:
            return [ROLES_ID.ADMIN, ROLES_ID.AGENCIA, ROLES_ID.CLIENTE, ROLES_ID.DUEÑO, ROLES_ID.REALTOR, ROLES_ID.SUB_AGENCIA, ROLES_ID.ASESOR]
    }
}


export const associatedChildren = (role) => {
    const user = JSON.parse(localStorage.getItem('userRV'))
    switch (role) {
        case ROLES_ID.AGENCIA:
            if(user.code === REAL_VISION_CODE)
                return [ROLES_ID.IBO]
            else
                return [ROLES_ID.SUB_AGENCIA, ROLES_ID.CLIENTE, ROLES_ID.ASESOR]
        case ROLES_ID.SUB_AGENCIA:
            return [ROLES_ID.ASESOR, ROLES_ID.CLIENTE]
        case ROLES_ID.REALTOR:
            return [ROLES_ID.CLIENTE]
        case ROLES_ID.ASESOR:
            return [ROLES_ID.CLIENTE]
        case ROLES_ID.IBO:
            return [ROLES_ID.CLIENTE]
        default:
            return [ROLES_ID.ADMIN, ROLES_ID.AGENCIA, ROLES_ID.CLIENTE, ROLES_ID.DUEÑO, ROLES_ID.REALTOR, ROLES_ID.SUB_AGENCIA, ROLES_ID.ASESOR]
    }
}

export const rolesByUser = (user, roles) => {
    const allowedRoles = associatedChildren(user.role.id)
    return roles.filter(role => allowedRoles.indexOf(role.id) !== -1)
}