import { associatedFather } from "app/helpers";
import { ROLES_ID } from "app/utils/constants/general";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { getForUserRelation, getUser } from "services/user";

const useUserRelation = (values) => {
  const queryClient = useQueryClient();

  const user = JSON.parse(localStorage.getItem("userRV"));

  const { mutate: getUsersByRol, data: usersByRol } = useMutation({
    mutationFn:
      user.role.id === ROLES_ID.ADMIN || values.roleId === ROLES_ID.CLIENTE
        ? getUser
        : getForUserRelation,
    onSuccess: (data) => {
      queryClient.setQueryData(["usersByRol"], () => {
        return data;
      });
    },
  });

  useEffect(() => {
    if (values.roleId) {
      getUsersByRol(
        user.role.id === ROLES_ID.ADMIN || values.roleId === ROLES_ID.CLIENTE
          ? { roleId: associatedFather(values.roleId) }
          : { roleId: associatedFather(values.roleId), userId: user.id }
      );
    }
  }, [values.roleId, getUsersByRol, user.id, user.role.id]);

  return {
    user,
    getUsersByRol,
    usersByRol,
  };
};

export default useUserRelation;
