import * as yup from "yup";

export const CreateAddressSchema = (t) => {
  return yup.object().shape({
    cityId: yup.number().required(t("validate.required")),
    address1: yup.string().required(t("validate.required")),
    address2: yup.string().required(t("validate.required")),
    zipCode: yup.string().required(t("validate.required")),
    indicative: yup.number().required(t("validate.required")),
    phone: yup.string().required(t("validate.required")),
  });
};
