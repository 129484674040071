import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const TabContextGeneral = ({ title, content }) => {
    const { t } = useTranslation()

    return (
        <Box sx={{ width: "100%" }} paddingX={5}>
            <Grid
                container
                spacing={4}
                style={{ width: "100%" }}
                justifyContent={"left"}
                alignItems={"left"}
            >
                <TabContext value={1}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: "divider",
                            width: "100%",
                        }}
                    >
                        <TabList centered>
                            <Tab label={t(title)} value={1} />
                        </TabList>
                    </Box>

                    <TabPanel value={1} style={{ width: "100%" }}>
                        {
                            content()
                        }
                    </TabPanel>
                </TabContext>
            </Grid>
        </Box>
    );
};

export default TabContextGeneral;
