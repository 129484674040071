import { Avatar } from "@mui/material";
import Actions from "app/shared/Actions";

export const resourcesColumns = (t) => {
  return [
    {
      field: "path",
      headerName: t("resources.path"),
      editable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <Avatar
            src={params.row.path}
            variant={"rounded"}
            sx={{
              width: 100,
              height: 100,
            }}
          />
        );
      },
    },
    {
      field: "resourceType",
      headerName: t("resources.resourceType"),
      editable: false,
      width: 180,
      renderCell: (params) => {
        return params.row.resourceType.name;
      },
    },
    {
      field: "id",
      headerName: t("resources.id"),
      editable: false,
      width: 300,
    },
    {
      field: "extension",
      headerName: t("resources.extension"),
      editable: false,
      width: 90,
    },
    {
      field: "actions",
      headerName: t("general.actions"),
      type: "string",
      editable: false,
      width: 170,
      renderCell: () => {
        return <Actions />;
      },
    },
  ];
};
