import * as yup from "yup";

export const CreateContactUsSchema = (t) => {
    const schema = {
        contactUsTypeId: yup.number().required(t("validate.required")),
        name: yup.string().required(t("validate.required")),
        lastName: yup.string().required(t("validate.required")),
        email: yup.string().email().required(t("validate.required")),
        indicative: yup.string().required(t("validate.required")),
        phone: yup.number().required(t("validate.required")),
        comment: yup.string().required(t("validate.required")),
        acceptPolicies: yup.string().required(t("validate.required"))
    }

    return yup.object().shape(schema)
}