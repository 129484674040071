import hydraAPI from "services/config/hydraAPI";

export const getMeasurement = async () => {
    const result = await hydraAPI().get("measurement/get");
    return result.data.data;
}

export const postProperty = async (property) => {
    const result = await hydraAPI().post("property/create", property);
    return result.data;
};

export const putProperty = async (property) => {
    const result = await hydraAPI().put("property/update", property);
    return result.data;
};

export const delProperty = async (id) => {
    const result = await hydraAPI().delete("property/delete", { data: { id } });
    return result.data;
};

export const getResourceType = async () => {
    const result = await hydraAPI().get("resourceType/get");
    return result.data.data;
}

export const getPropertiesReport = async (data = {}) => {
    const result = await hydraAPI().post(`property/get`, data);
    return result.data.data;
};