import { Grid } from '@mui/material';
import CardButton from 'app/layouts/card-button/CardButton';
import React from 'react';
import ListContactUs from './components/contactUsList';

const ContactUs = () => {
    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
                <CardButton navigateTo={"/contact-us/create"} />
            </Grid>
            <Grid item xs={12} lg={12}>
                <ListContactUs />
            </Grid>
        </Grid>
    );
}

export default ContactUs;
