import Alerts from "app/shared/Alerts";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { update as updateUser } from "services/resources";

const useUpdateResource = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { success } = Alerts();

  const { mutate: update, isLoading } = useMutation({
    mutationFn: updateUser,
    onSuccess: async () => {
      queryClient.invalidateQueries("getResources");
      success("", t("resources.deleteResponse"), "success");
    },
  });

  return {
    update,
    isLoading,
  };
};

export default useUpdateResource;
