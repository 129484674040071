import { Box, Grid } from "@mui/material";
import React from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
/* import { getUserConfiguration } from "services/user";
import { useQuery } from "react-query"; */

const SubAgencyList = ({ title = "Lista de SubAgencias", ViewButtonCreate, isFromDetail = false }) => {
    const navigate = useNavigate()

    const columns = [
        {
            field: "id",
            headerName: "Id",
            editable: false
        },
        {
            field: "agencyName",
            headerName: "Agency Name",
            editable: false,
            width: 250
        },
        {
            field: "description",
            headerName: "Description",
            editable: false,
            width: 300
        },
        {
            field: "active",
            headerName: "Active",
            editable: false,
            renderCell: (params) => {
                return params.value ? <CheckCircleOutlineIcon /> : <CancelIcon />
            },
            width: 50
        }
    ]

    const handleCellClick = (params) => {
        if (isFromDetail) {
            alert("Agencia seleccionada: " + params.id)
        } else {
            navigate(`/subagency/detail/${params.id}`)
        }
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
                <JumboCardQuick title={title}>
                    <Box sx={{ height: 400, width: "100%" }}>
                        {
                            isFromDetail && (
                                ViewButtonCreate()
                            )
                        }
                        {
                            /** Acá va el select de agencias solo visible para el administrador */
                        }
                        <DataGrid
                            rows={[]}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            // checkboxSelection
                            onCellClick={handleCellClick}
                            editRowsModel={false}
                            resizable={true}
                            // loading={true}
                            experimentalFeatures={{ newEditingApi: true }}
                        />
                    </Box>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default SubAgencyList;
