import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { LoadingButton } from "@mui/lab";
import { Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const Filters = () => {
  const { t } = useTranslation();

  return (
    <JumboCardQuick
      title={
        <Typography variant={"h4"}>{t("widgets.title.filters")}</Typography>
      }
    >
      <Formik
        initialValues={{}}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={{}}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
        }}
      >
        {({
          handleChange,
          errors,
          values,
          setFieldValue,
          validateField,
          resetForm,
        }) => (
          <Form autoComplete="off" noValidate>
            <Grid
              container
              spacing={4}
              style={{ width: "100%" }}
              justifyContent={"left"}
              alignItems={"left"}
            >
              <Grid item xs={4}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Estado"
                  defaultValue="EUR"
                  variant="standard"
                  name="state"
                  fullWidth
                  error={errors.state ?? false}
                  helperText={errors.state ?? ""}
                  onChange={handleChange}
                  value={values.state}
                  onBlur={() => {
                    validateField("state");
                  }}
                >
                  {[
                    {
                      value: 1,
                      label: "Nuevo",
                    },
                    {
                      value: 0,
                      label: "Viejo",
                    },
                  ].map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  label={"Description"}
                  name="description"
                  variant="standard"
                  onChange={handleChange}
                  value={values.des}
                  error={errors.des ?? false}
                  helperText={errors.des && <div>error</div>}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  label={"Description"}
                  name="description"
                  variant="standard"
                  onChange={handleChange}
                  value={values.des}
                  error={errors.des ?? false}
                  helperText={errors.des && <div>error</div>}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  label={"Description"}
                  name="description"
                  variant="standard"
                  onChange={handleChange}
                  value={values.des}
                  error={errors.des ?? false}
                  helperText={errors.des && <div>error</div>}
                />
              </Grid>

              <Grid item xs={12} textAlign={"center"}>
                <Stack spacing={2} direction="row" justifyContent={"center"}>
                  <LoadingButton type="submit" variant="contained">
                    {t("forms.create")}
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    onClick={() => {
                      resetForm();
                    }}
                  >
                    {t("forms.clear")}
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </JumboCardQuick>
  );
};

export default Filters;
