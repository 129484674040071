import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Actions from 'app/pages/users/list/components/Actions';

export const userColumns = (t) => {
  return [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "code",
      headerName: t("user.code"),
      editable: false,
    },
    {
      field: "name",
      headerName: t("user.name"),
      width: 200,
      editable: false,
    },
    {
      field: "lastName",
      headerName: t("user.lastName"),
      width: 200,
      editable: false,
    },
    {
      field: "email",
      headerName: t("user.email"),
      editable: false,
      width: 250,
    },
    {
      field: "active",
      headerName: t("user.active"),
      editable: false,
      width: 80,
      renderCell: (params) => {
        return params.value ? <CheckCircleOutlineIcon /> : <CancelIcon />;
      },
    },
    {
      field: "actions",
      headerName: t("user.actions"),
      type: "string",
      editable: false,
      width: 170,
      renderCell: () => {
        return <Actions />;
      },
    },
  ];
};
