import { Grid } from "@mui/material";
import React from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import AgencyListContent from "./components/AgencyListContent";

const AgencyList = ({ title = "Lista de agencias", ViewButtonCreate, isFromDetail = false }) => {


    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} lg={12}>
                <JumboCardQuick title={title}>
                    <AgencyListContent ViewButtonCreate={ViewButtonCreate} isFromDetail={isFromDetail} />
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default AgencyList;
