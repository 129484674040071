import { LoadingButton } from '@mui/lab';
import SubAgencyList from 'app/pages/subAgency/list';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SubAgency = ({ userId }) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const ViewButtonCreate = () => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingButton
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                onClick={() => {
                    navigate("/subagency/create/" + userId)
                }}
            >
                {t("forms.create")}
            </LoadingButton>
        </div>
    )

    return (
        <SubAgencyList title={"SubAgencias creadas"} ViewButtonCreate={ViewButtonCreate} isFromDetail={true} />
    );
}

export default SubAgency;
