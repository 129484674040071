import { Grid } from "@mui/material";

const LoadInventory = () => {
  const onChange = (e) => {
  };
  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={12}>
        <input type="file" name="excel" onChange={onChange} />
      </Grid>
    </Grid>
  );
};

export default LoadInventory;
