import { reqCreateProperty } from "app/utils/requests/property";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import getBusinessType from "services/getBusinessType";
import getPropiertyType from "services/getPropiertyType";
import getStateType from "services/getStateType";
import { getMeasurement } from "services/property";
import { postProperty, putProperty } from "services/property";
import { postFile } from "services/upload";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "center",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const useProperty = (typeForm, params, handleNext) => {
  const navigate = useNavigate();
  const [propertyId, setPropertyId] = useState(undefined);
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const createProperty = useMutation({
    mutationFn: postProperty,
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: error,
      });
    },
    onSuccess: ({ data }) => {
      setPropertyId(data.id);
      handleNext();
    },
  });

  const { mutate: updateProperty, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: putProperty,
    onError: () => {
      Toast.fire({
        icon: "error",
        title: t("errors.update"),
      });
    },
    onSuccess: () => {
      Toast.fire({
        icon: "success",
        text: t("property.successUpdate"),
      });
      queryClient.invalidateQueries("getUserConfiguration");
      navigate("/properties");
    },
  });

  const { mutate:uploadImages, isLoading: isLoadingResources} = useMutation({
    mutationFn: postFile,
    onError: (error) => {
      Toast.fire({
        icon: "error",
        title: error,
      });
    },
    onSuccess: () => {
      Toast.fire({
        icon: "success",
        title: t("property.successCreate"),
      });
      navigate("/preview");
    },
  });

  const { data: businessType } = useQuery({
    queryKey: "getBusinessType",
    queryFn: getBusinessType,
  });

  const { data: propiertyType } = useQuery({
    queryKey: "getPropiertyType",
    queryFn: getPropiertyType,
  });

  const { data: stateType } = useQuery({
    queryKey: "getStateType",
    queryFn: getStateType,
  });

  const { data: measurements } = useQuery({
    queryKey: "getMeasurement",
    queryFn: getMeasurement,
  });

  const onSubmit = async (values) => {
    const property = reqCreateProperty(values);
    if (typeForm === "Create") {
      createProperty.mutate(property);
    } else if (typeForm === "Update") {
      updateProperty({ ...property, id: Number(params.id) });
    }
  };

  return {
    businessType,
    propiertyType,
    stateType,
    isLoading: createProperty.isLoading,
    isLoadingUpdate,
    isLoadingResources,
    onSubmit,
    uploadImages,
    propertyId,
    measurements,
  };
};

export default useProperty;
