import {
  // Autocomplete,
  Box,
  Button,
  // FormControl,
  Grid,
  Stack,
  // TextField,
} from "@mui/material";
import React from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { DataGrid } from "@mui/x-data-grid";
import UseListUser from "app/hooks/user/useListUser";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { formatRoles } from "app/utils/formats";
// import UseRole from "app/hooks/user/useRole";

const CustomersList = () => {
  const { t } = useTranslation();
  const { columns, rowsMain, isloadingMain, handleClick } =
    UseListUser();
  // const { roles } = UseRole();

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={12}>
        <JumboCardQuick>
          <Stack spacing={2} direction="row" justifyContent={"center"}>
            <Link to={"/user/create"} style={{ textDecoration: "none" }}>
              <Button variant="contained">{t("forms.create")}</Button>
            </Link>
          </Stack>
        </JumboCardQuick>
      </Grid>
      <Grid item xs={12} lg={12}>
        <JumboCardQuick>
          {/* <Grid item xs={12} sm={4} lg={4}>
            <FormControl fullWidth variant="standard">
              <Autocomplete
                options={formatRoles(roles) ?? []}
                noOptionsText={t("general.noData")}
                onChange={(_, value) => handleChangeFilter(value.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label={t("user.role")}
                  />
                )}
                renderOption={(props, option) => (
                  <Box {...props}>{option?.label}</Box>
                )}
              />
            </FormControl>
          </Grid> */}
          <Box sx={{ height: 800, width: "100%" }}>
            <DataGrid
              sx={{ marginTop: "50px" }}
              rows={rowsMain ? (rowsMain === "" ? [] : rowsMain) : []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              onCellClick={handleClick}
              editRowsModel={false}
              resizable={true}
              loading={isloadingMain}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </JumboCardQuick>
      </Grid>
    </Grid>
  );
};

export default CustomersList;
