import { useQuery } from "react-query";
import { getCountries } from "services/location";

const useCountry = () => {
  const { data: countries } = useQuery({
    queryKey: "countries",
    queryFn: getCountries,
  });

  return {
    countries,
  };
};

export default useCountry;
