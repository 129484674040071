import { useMutation, useQuery, useQueryClient } from 'react-query';
import getBusinessType from 'services/getBusinessType';
import getPropiertyType from 'services/getPropiertyType';
import getStateType from 'services/getStateType';
import { getPropertiesReport } from 'services/property';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const UsePropertyFilter = () => {
    const queryClient = useQueryClient()

    const { mutate, isLoading } = useMutation({
        mutationFn: getPropertiesReport,
        onSuccess: (result) => {
            queryClient.setQueryData("getPropertiesReport", result)
        },
        onError: (error) => {
            Toast.fire({
                icon: "error",
                title: error,
            });
        }
    })

    const { data: stateType } = useQuery({
        queryKey: "getStateType",
        queryFn: getStateType
    })

    const { data: propiertyType } = useQuery({
        queryKey: "getPropiertyType",
        queryFn: getPropiertyType
    })

    const { data: businessType } = useQuery({
        queryKey: "getBusinessType",
        queryFn: getBusinessType
    })

    return {
        stateType,
        propiertyType,
        businessType,
        isLoading,
        mutate
    }
}

export default UsePropertyFilter;
