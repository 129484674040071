import { TabPanel } from "@mui/lab";
import TabContextForms from "app/shared/TabContextForms";
import React from "react";
import LocationForm from "./locationForm";
import PersonalDataForm from "./personalDataForm";

const tabData = [
    {
        label: "Datos personales",
        value: "1"
    },
    {
        label: "Ubicación",
        value: "2"
    },
    {
        label: "Permisos",
        value: "3"
    },
]

const CreateForm = () => {
    const [value, setValue] = React.useState("1");

    const onSubmit = (values) => {
        let data = JSON.parse(localStorage.getItem('realvision.customers'));
        if (!data) {
            data = [values]
        } else {
            data.push(values)
        }
        localStorage.setItem('realvision.customers', JSON.stringify(data))
        setValue("3");
    }

    const tabForms = (handleChange, errors, values, setFieldValue, validateField) => (
        <>
            <TabPanel value="1" style={{ width: "100%" }}>
                <PersonalDataForm
                    handleChange={handleChange}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    validateField={validateField}
                />
            </TabPanel>
            <TabPanel value="2" style={{ width: "100%" }}>
                <LocationForm
                    handleChange={handleChange}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    validateField={validateField}
                />
            </TabPanel>
            <TabPanel value="3" style={{ width: "100%" }}>
                Item Three
            </TabPanel>
        </>
    )

    return (
        <TabContextForms
            onSubmit={onSubmit}
            value={value}
            setValue={setValue}
            tabForms={tabForms}
            tabData={tabData}
        />
    );
};

export default CreateForm;
