import { LoadingButton } from "@mui/lab";
import { Grid, MenuItem, TextField } from "@mui/material";
import UseCreateUser from "app/hooks/user/useCreateUser";
import UseRole from "app/hooks/user/useRole";
import { CreateUserSchema } from "app/utils/schemas/user";
import { Form, Formik } from "formik";
import React from "react";
import UserRelation from "./userRelation";

const UserDataForm = ({ disableFields, user, t }) => {
  const { roles } = UseRole();
  const { onSubmit, isLoading, initialValues, setCodeText, disbabledButton } =
    UseCreateUser();

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={CreateUserSchema(t)}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ handleChange, errors, validateField, values, setFieldValue }) => (
        <Form autoComplete="off" noValidate>
          <Grid
            container
            spacing={4}
            style={{ width: "100%" }}
            justifyContent={"left"}
            alignItems={"left"}
          >
            <Grid item xs={12} lg={6}>
              <TextField
                required
                fullWidth
                label={t("forms.name")}
                name="name"
                variant="standard"
                onChange={handleChange}
                onBlur={() => {
                  validateField("name");
                }}
                value={user?.name}
                error={errors.name ?? false}
                helperText={errors.name ?? ""}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                fullWidth
                label={t("forms.lastName")}
                name="lastName"
                variant="standard"
                onChange={handleChange}
                onBlur={() => {
                  validateField("lastName");
                }}
                value={user?.lastName}
                error={errors.lastName ?? false}
                helperText={errors.lastName ?? ""}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                fullWidth
                label={t("forms.userCode")}
                name="code"
                variant="standard"
                onChange={handleChange}
                onKeyUp={(e) => setCodeText(e.target.value)}
                onBlur={() => {
                  validateField("code");
                }}
                value={user?.code}
                error={errors.code ?? false}
                helperText={errors.code ?? ""}
                disabled={disableFields}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                required
                fullWidth
                label={t("login.email")}
                name="email"
                variant="standard"
                onChange={handleChange}
                onBlur={() => {
                  validateField("email");
                }}
                value={user?.email}
                type="email"
                error={errors.email ?? false}
                helperText={errors.email ?? ""}
                disabled={disableFields}
              />
            </Grid>
            {!user && (
              <>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    type={"password"}
                    fullWidth
                    label={t("login.password")}
                    name="password"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => {
                      validateField("password");
                    }}
                    value={user?.password}
                    error={errors.password ?? false}
                    helperText={errors.password ?? ""}
                    disabled={disableFields}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    required
                    type={"password"}
                    fullWidth
                    label={t("login.confirmPassword")}
                    name="confirmPassword"
                    variant="standard"
                    onChange={handleChange}
                    onBlur={() => {
                      validateField("confirmPassword");
                    }}
                    value={user?.confirmPassword}
                    error={errors.confirmPassword ?? false}
                    helperText={errors.confirmPassword ?? ""}
                    disabled={disableFields}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={6}>
              <TextField
                select
                label="Roles"
                variant="standard"
                name="roleId"
                fullWidth
                error={errors.roleId ?? false}
                helperText={errors.roleId ?? ""}
                onChange={handleChange}
                onBlur={() => {
                  validateField("roleId");
                }}
                value={user?.role?.id}
                disabled={disableFields}
              >
                {roles?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {
              <UserRelation
                handleChange={handleChange}
                errors={errors}
                values={values}
                setFieldValue={setFieldValue}
                t={t}
              />
            }
            <Grid item xs={12} textAlign={"center"}>
              {!user && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isLoading}
                  disabled={disbabledButton}
                >
                  {t("forms.create")}
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default UserDataForm;
