import { reqCreateUserConfiguration } from 'app/utils/requests/user';
import { useMutation, useQueryClient } from 'react-query';
import { postUserConfiguration } from 'services/user';

const useConfigurationUser = (userId) => {
    const queryClient = useQueryClient()

    /* const { mutate: createUserRelation } = useMutation({
        mutationFn: postUserRelation,
        onSuccess: () => {
            queryClient.invalidateQueries('getUserRelation')
        }
    }) */

    const createUserConfiguration = useMutation({
        mutationFn: postUserConfiguration,
        onSuccess: (response) => {
            queryClient.invalidateQueries("getUserConfiguration")
            /* createUserRelation({
                agencyId: response.data.data.id,
                userId: Number(userId)
            }) */
        },
        onError: (error) => {
            alert(error)
        }
    })

    const onSubmit = (values) => {
        const req = reqCreateUserConfiguration({ ...values, userId: values.userId ?? userId })
        createUserConfiguration.mutate(req)
    }

    return {
        onSubmit,
        isLoading: createUserConfiguration.isLoading
    }
}

export default useConfigurationUser;
