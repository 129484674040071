import JumboCardQuick from '@jumbo/components/JumboCardQuick';
import { Grid } from '@mui/material';
import React from 'react';
import CreateForm from './components/createForm';

const CreateCustomer = () => {
    return (
        <JumboCardQuick title={"Crear cliente"}>
            <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                    <CreateForm />
                </Grid>
            </Grid>
        </JumboCardQuick>
    );
}

export default CreateCustomer;
