import hydraAPI from "services/config/hydraAPI";

export const postContactUs = async (data) => {
    const userRV = JSON.parse(localStorage.getItem('userRV'))
    const result = await hydraAPI().post("contactUs/create", { ...data, createdBy: userRV.createdBy });
    return result.data.data;
}

export const getContactUs = async () => {
    const result = await hydraAPI().get("contactUs/get");
    return result.data.data;
}

export const getContactUsType = async () => {
    const result = await hydraAPI().get("contactUsType/get");
    return result.data.data;
}
