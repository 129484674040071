import hydraAPI from "services/config/hydraAPI";

export const getAmenities = async () => {
    const result = await hydraAPI().get("/amenities/get");
    return result.data.data;
}

export const getPropertyTypes = async () => {
    const result = await hydraAPI().post("propertyType/get");
    return result.data.data;
}

