import * as yup from "yup";

export const CreateUserSchema = (t) => {
  const schema = {
    name: yup.string().required(t("validate.required")),
    lastName: yup.string().required(t("validate.required")),
    code: yup
      .string()
      .required(t("validate.required"))
      .max(10, t("validate.maxUserCode"))
      .matches(/^[a-zA-Z\-0-9]+$/, t("valite.matches")),
    email: yup
      .string()
      .email(t("validate.email"))
      .required(t("validate.required")),
    password: yup.string().required(t("validate.required")),
    confirmPassword: yup
      .string()
      .required(t("validate.required"))
      .oneOf([yup.ref("password"), null], t("validate.noMatch")),
    roleId: yup.number(t("validate.required")).required(t("validate.required")),
  };

  return yup.object().shape(schema);
};

export const CreateUserConfigurationSchema = (t) => {
  const schema = {
    identification: yup.string().required(t("validate.required")),
    agencyName: yup.string().required(t("validate.required")),
    description: yup.string().required(t("validate.required")),
  };

  return yup.object().shape(schema);
};

export const ForgotPasswordSchema = () => {
  return yup.object().shape({
    email: yup.string().required("validate.required").email("validate.email"),
  });
};

export const RestorePasswordSchema = () => {
  return yup.object().shape({
    code: yup.string().required("validate.required").max(6, "validate.maxCode"),
    password: yup
      .string()
      .required("validate.required")
      .min(6, "validate.password"),
    confirmPassword: yup
      .string()
      .required("validate.required")
      .oneOf([yup.ref("password"), null], "validate.noMatch"),
  });
};
