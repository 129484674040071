
import DeleteIcon from "@mui/icons-material/Delete";
import { ListItemIcon } from "@mui/material";

const Actions = () => {

  return (
    <div>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
    </div>
  );
}

export default Actions
