import { Grid } from "@mui/material";
import CardButton from "app/layouts/card-button/CardButton";
import ListDataGrid from "app/layouts/card-datagrid/ListDataGrid";
import { citiesColumns } from "app/utils/tableColumns/cities";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getCities } from "services/location";

const City = () => {
  const { t } = useTranslation();

  const { data: rows, isLoading } = useQuery({
    queryKey: "getCities",
    queryFn: getCities,
  });
  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={12}>
        <CardButton disabled={true} />
      </Grid>
      <Grid item xs={12} lg={12}>
        <ListDataGrid
          rows={rows}
          columns={citiesColumns(t)}
          isLoading={isLoading}
          pageSize={10}
        />
      </Grid>
    </Grid>
  );
};

export default City;
