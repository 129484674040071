import * as yup from "yup";

export const CreateCustomerSchema = (t) => {
    const schema = {
        name: yup.string().required(t("validate.required")),
        lastName: yup.string().required(t("validate.required")),
        email: yup.string().email().required(t("validate.required")),
        password: yup.string().required(t("validate.required")),
        roleId: yup.number().required(t("validate.required"))
    }

    return yup.object().shape(schema)
}