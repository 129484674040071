import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

const List = ({ properties }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      {properties?.length > 0 &&
        properties.map((item, idx) => (
          <Grid item xs={12} sm={6} lg={4}>
            <Card
              sx={{ maxWidth: 345 }}
              key={idx}
              onClick={() => navigate("/property/detail-preview/" + item.id, {
                state: item,
              })}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={item?.images[0]?.path}
                  alt="show image"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                  ></Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
    </Grid>
  );
};

export default List;
