import ListDataGrid from 'app/layouts/card-datagrid/ListDataGrid';
import React from 'react';
import { useQuery } from 'react-query';
import { getPropertiesReport } from 'services/property';

const ReportList = () => {
    const columns = [
        { field: "id", headerName: "ID", width: 90 },
        {
            field: "title",
            headerName: "Title",
            editable: false,
            width: 300
        },
        {
            field: "code",
            headerName: "Code",
            editable: false,
            width: 100
        },
        {
            field: "currencyCode",
            headerName: "Currency",
            editable: false,
            width: 100,
            valueGetter: (params) => params.row.currency.code
        },
        {
            field: "price",
            headerName: "Price",
            editable: false,
            width: 100
        },
        {
            field: "yearConstruction",
            headerName: "Year construction",
            editable: false,
            width: 100
        },
        {
            field: "businessType",
            headerName: "Business type",
            editable: false,
            width: 200,
            valueGetter: (params) => params.row.businessType.name
        },
        {
            field: "cityId",
            headerName: "City",
            editable: false,
            width: 100,
            valueGetter: (params) => params.row.city.name
        },
        {
            field: "propertyType",
            headerName: "Property type",
            editable: false,
            width: 200,
            valueGetter: (params) => params.row.propertyType.name
        },
        {
            field: "stateType",
            headerName: "State type",
            editable: false,
            width: 200,
            valueGetter: (params) => params.row.stateType.name
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "string",
            editable: false,
            width: 170,
            /* renderCell: (params) => {
                return <Actions />;
            }, */
        },
    ];

    const { data: rows, isLoading } = useQuery({
        queryKey: "getPropertiesReport",
        queryFn: getPropertiesReport
    })

    return (
        <ListDataGrid rows={rows} columns={columns} isLoading={isLoading} pageSize={10} height={650} />
    );
}

export default ReportList;
