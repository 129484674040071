import { Card, CardContent, Grid } from "@mui/material";
import { useEffect } from "react";
import StepperView from "./components/StepperView";

const Create = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={12}>
        <Card>
          <CardContent>
            <StepperView />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Create;
