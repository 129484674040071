import { Grid } from "@mui/material";
import UseUserDetail from "app/hooks/user/useUserDetail";
import Loading from "app/layouts/shared/loading";
import React from "react";
import UserFormContent from "../form/components/userFormContent";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import TabContextGeneral from "app/shared/TabContextGeneral";
import ConfigInformation from "./components/configInformation";
import { useTranslation } from "react-i18next";
import { ROLES_ID } from "app/utils/constants/general";
import Address from "app/shared/Address";
import Permissions from "../permissions";

const UserDetail = () => {
  const { t } = useTranslation();
  const { user, isLoading } = UseUserDetail();

  if (isLoading) {
    return <Loading />;
  }

  const ViewAddress = () => <Address />;
  const ViewPermissions = () => <Permissions />;
  const ViewConfigUser = () => <ConfigInformation />;

  return (
    <JumboCardQuick>
      <Grid container paddingY={2}>
        <Grid item xs={12}>
          <UserFormContent user={user} />
          {(user.role.id === ROLES_ID.AGENCIA ||
            user.role.id === ROLES_ID.SUB_AGENCIA) && (
            <div style={{ marginTop: "50px" }}>
              <TabContextGeneral
                title={t("title.configUser")}
                content={ViewConfigUser}
              />
            </div>
          )}
          <div style={{ marginTop: "50px" }}>
            <TabContextGeneral
              title={t("title.addresses")}
              content={ViewAddress}
            />
          </div>
          <div style={{ marginTop: "50px" }}>
            <TabContextGeneral
              title={t("title.permissions")}
              content={ViewPermissions}
            />
          </div>
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default UserDetail;
