import ListDataGrid from 'app/layouts/card-datagrid/ListDataGrid';
import { contactUsColumns } from 'app/utils/tableColumns/contactUs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getContactUs } from 'services/contactUs';

const ContactUsList = () => {
    
    const { t } = useTranslation()

    const { data: rows, isLoading } = useQuery({
        queryKey: "getContactUs",
        queryFn: getContactUs
    })

    return (
        <ListDataGrid rows={rows} columns={contactUsColumns(t)} isLoading={isLoading} />
    );
}

export default ContactUsList;
