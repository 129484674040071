import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";

const Home = () => {

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3.75}>
        <Grid item xs={12} lg={12}>
          <JumboCardQuick>
            <Stack spacing={2} direction="row" justifyContent={"center"}>
              Página de inicio
            </Stack>
          </JumboCardQuick>
          {/*<BasicTextFields /> */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Home;
