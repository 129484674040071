import Span from "@jumbo/shared/Span";
import { Grid, Typography } from "@mui/material";
import React from "react";

const ItemDetail = ({ icon, title, description }) => {
  return (
    <Grid item xs={6} sm={6} md={4} lg={6} xl={4}>
      <Span
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: 13,
        }}
      >
        {icon}
        <Span
          sx={{
            display: "inline-block",
            fontSize: 13,
            padding: "10px",
          }}
        >
          <Typography variant={"h5"} mb={1.25}>
            {title}
            <Typography variant={"body1"} color={"text.secondary"} mb={0.5}>
              {description}
            </Typography>
          </Typography>
        </Span>
      </Span>
    </Grid>
  );
};

export default ItemDetail;
