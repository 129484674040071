import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { addressColumns } from "app/utils/tableColumns/address";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAddress } from "services/user";

const AddressList = () => {
  const { userId } = useParams();
  const { t } = useTranslation();

  const { data, isloading } = useQuery({
    queryKey: ["getAddress"],
    queryFn: () => {
      return getAddress(userId);
    },
  });
  
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data ? (data === "" ? [] : data) : []}
        columns={addressColumns(t)}
        pageSize={5}
        rowsPerPageOptions={[5]}
        editRowsModel={false}
        resizable={true}
        loading={isloading}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default AddressList;
