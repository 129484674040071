import { Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const FOR_SALE = 2;
const FOR_RENT = 1;
const FOR_SALE_RENT = 3;

const CustomChip = ({ idType }) => {
  const { t } = useTranslation();

  const view = () => {
    switch (idType) {
      case FOR_SALE:
        return (
          <Chip
            label={t("property.forSale")}
            size={"small"}
            color={"warning"}
            sx={{ mb: 1 }}
          />
        );
      case FOR_RENT:
        return (
          <Chip
            label={t("property.forRent")}
            size={"small"}
            color={"success"}
            sx={{ mb: 1 }}
          />
        );
      case FOR_SALE_RENT:
        return (
          <Chip
            label={t("property.forSale") + " - " + t("property.forRent")}
            size={"small"}
            color={"success"}
            sx={{ mb: 1 }}
          />
        );

      default:
        break;
    }
  };
  return view();
};

export default CustomChip;
