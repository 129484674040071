import React from "react";
import { useTranslation } from "react-i18next";
import UserDataForm from "./userForm";

const UserFormContent = ({ user }) => {
  const { t } = useTranslation();

  return (
      <UserDataForm disableFields={user ? true : false} user={user} t={t} />
  );
};

export default UserFormContent;
