import { Box, Grid } from "@mui/material";
import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import useGetResources from "app/hooks/resources/useGetResources";

const List = () => {
  const { columns, resources, isLoading, handleClick } = useGetResources()

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} lg={12}>
          <Box sx={{ height: 500, width: "100%" }}>
            <DataGrid
              sx={{ marginTop: "50px" }}
              rows={resources ?? []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              onCellClick={handleClick}
              editRowsModel={false}
              resizable={true}
              loading={isLoading}
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
      </Grid>
    </Grid>
  );
};

export default List;
